import React from "react";
import {
  Box,

  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList, Typography,
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Home as HomeIcon,
  Info as InfoIcon,
  Movie as MovieIcon,
  Backup as BackupIcon,
  Inventory as InventoryIcon,
  Psychology as PsychologyIcon,
  Collections as CollectionsIcon,
  Description as DescriptionIcon,
  AutoAwesome as AutoAwesomeIcon,
  RequestQuote as RequestQuoteIcon,
  BakeryDining as BakeryDiningIcon,
  ImageAspectRatio as ImageAspectRatioIcon,
  DriveFileRenameOutline as DriveFileRenameOutlineIcon
} from "@mui/icons-material";
import clsx from "clsx";
import {
  useLocation
} from "react-router-dom";
import queryString from "query-string";
import {palette} from "../../../../../../theme/common";
import {getObjectKeys} from "../../../../../../helper/object";
import moment from "moment";

const list = [
  {
    Icon: HomeIcon,
    title: "Основные параметры",
    search: "main-info",
    errorKeys: ['code', 'sku', 'model'],
  },
  {
    Icon: DriveFileRenameOutlineIcon,
    title: "Наименования товара",
    search: "name-product",
    errorKeys: ['ru.name','ru.locale_code','en.name','kaz.name',],
  },
  {
    Icon: DescriptionIcon,
    title: "Описание товара",
    search: "product-description",
  },
  {
    Icon: RequestQuoteIcon,
    title: "Цены товара",
    search: "prices",
  },
  {
    Icon: InfoIcon,
    title: "Дополнительная информация о товаре",
    search: "additional-information",
  },
  {
    Icon: ImageAspectRatioIcon,
    title: "Камень и металлы",
    search: "stone-metal",
    errorKeys: ['metal_ids'],
  },
  {
    Icon: PsychologyIcon,
    title: "SEO и Meta",
    search: "seo-meta",
  },
  {
    Icon: AutoAwesomeIcon,
    title: "Гарантии",
    search: "guarantee",
  },
  {
    Icon: AutoAwesomeIcon,
    title: "Вставки",
    search: "inserts",
    errorKeysPartial: ['inserts'],
  },
  // {
  //   Icon: GppMaybeIcon,
  //   title: "Сертификаты",
  //   search: "certificates",
  // },
  {
    Icon: MovieIcon,
    title: "Видео",
    search: "videos",
    errorKeys: ['videos'],
  },
  {
    Icon: InventoryIcon,
    title: "Гарнитуры",
    search: "headsets",
  },
  {
    Icon: CollectionsIcon,
    title: "Коллекции",
    search: "collection",
    errorKeysPartial: ['collection_ids']
  },
  {
    Icon: BackupIcon,
    title: "Файлы",
    search: "files",
  },
  {
    Icon: BakeryDiningIcon,
    title: "VIP",
    search: "vip-information",
    errorKeys: [
      'premium_data.premium_background_first_file_id', 'premium_data.premium_background_mobile_file_id', 'premium_data.premium_background_second_file_id',
      'premium_data.premium_background_third_file_id', 'premium_data.premium_central_stone_field_mobile_file_id', 'premium_data.premium_central_stone_purity',
      'premium_data.premium_central_stone_size', 'premium_data.premium_certificate_title', 'premium_data.premium_certificate_title_file_id', 'premium_data.premium_collection_first_file_id',
      'premium_data.premium_collection_second_file_id', 'premium_data.premium_insert_file_id', 'premium_data.premium_insert_mobile_file_id', 'premium_data.premium_stone_file_id',
      'premium_data.ru.premium_additional_description', 'premium_data.ru.premium_central_stone_color', 'premium_data.ru.premium_central_stone_description',
      'premium_data.ru.premium_central_stone_field_description', 'premium_data.ru.premium_central_stone_title', 'premium_data.ru.premium_collection_description',
      'premium_data.ru.premium_history_description', 'premium_data.ru.premium_stone_description',
    ],
  },
  {
    Icon: BakeryDiningIcon,
    title: "Камень",
    search: "stone-section",
    errorKeys: [
      'stone_data.ru.stone_color', 'stone_data.ru.stone_cut_type', 'stone_data.ru.stone_field',
      'stone_data.ru.stone_form', 'stone_data.ru.stone_gentrification', 'stone_data.ru.stone_origin',
      'stone_data.ru.stone_size', 'stone_data.ru.stone_transparency', 'stone_data.stone_field_file_id',
      'stone_data.stone_certificate_file_id', 'stone_data.stone_certificate',
      'stone_data.stone_background_of_the_gemological_description_file_id', 'stone_data.stone_version_file_ids',
    ]
  },
  // {
  //   Icon: PermMediaIcon,
  //   title: "Изображение",
  //   search: "images",
  // }
];

const NavigationMenu = (props) => {
  const {
    tab,
    product,
    onChange,
    disabledVip,
    disabledStone,

    errors,
    touched
  } = props;
  const classes = useStyles();
  const search = useLocation()?.search || "";
  const activeSearch = tab || queryString.parse(search)?.content || "main-info";

  const _activeErrors = (element) => {
    const errorKeys = element?.errorKeys || [];
    const errorKeysPartial = element?.errorKeysPartial || [];
    if (!errorKeys.length && !errorKeysPartial.length) {
      return 0
    }

    const errorObjectKeys = getObjectKeys(errors);

    if (errorKeys.length > 0) {
      return errorObjectKeys.filter((t) => errorKeys.includes(t)).length
    }
    if (errorKeysPartial.length > 0) {
      return errorObjectKeys.filter((errorKey) => {
        return errorKeysPartial.find((t) => errorKey.indexOf(t) > -1)
      }).length
    }
    return 0
  }

  return (
    <Box className={classes.root}>
      <MenuList>
        {list.map((element, index) => {
          const isDisabled = Boolean(
            (element.search === "vip-information" && disabledVip) ||
            (element.search === "stone-section" && disabledStone)
          );

          return (
            <MenuItem
              key={`element-${index}`}
              className={clsx({
                [classes.menuItem]: true,
                'active': activeSearch === element.search
              })}
              disabled={isDisabled}
              onClick={onChange.bind(this, element.search)}
            >
              <ListItemIcon>
                <element.Icon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
              <span style={{whiteSpace: "initial"}}>
                {element.title}
              </span>
              </ListItemText>

              {Boolean(_activeErrors(element)) && (
                <Typography variant="body2" sx={{color: `${palette.error.dark}!important`}}>
                  { _activeErrors(element) }
                </Typography>
              )}
            </MenuItem>
          )
        })}
      </MenuList>

      <Box px={2}>
        <Typography variant="caption" component="div">
          Дата создания: {product?.created_at ? moment(product?.created_at).format("DD.MM.YYYY HH:mm:ss") : '-'}
        </Typography>
        <Typography variant="caption" component="div">
          Дата обновления: {product?.updated_at ? moment(product?.updated_at).format("DD.MM.YYYY HH:mm:ss") : '-'}
        </Typography>
        <Typography variant="caption" component="div">
          Пользователь: {product?.user?.email || "-"}
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    padding: "10px 0",
    borderRadius: "10px"
  },

  menuItem: {
    "& .MuiSvgIcon-root": {
      color: "#8B8C8D"
    },
    "& .MuiTypography-root": {
      color: "#8B8C8D"
    },

    "&.active": {
      "& .MuiSvgIcon-root": {
        color: "#d6b46e"
      },
      "& .MuiTypography-root": {
        color: "#d6b46e"
      },
    }
  },
}));

export default React.memo(NavigationMenu)
