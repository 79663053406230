import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  Backdrop,
  Typography,
  CircularProgress
} from "@mui/material";
import {
  Save as SaveIcon,
  Delete as DeleteIcon
} from "@mui/icons-material";
import {
  Formik
} from "formik";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import {
  Images,
  SeoMeta,
  ProductIds,
  NameSection,
  NavigationMenu,
  MainInformation,
  ProductDescription
} from "./components";
import * as Yup from "yup";
import agent from "../../../../agent/agent";
import queryString from "query-string";
import {changeFileSort, uploadFile} from "../../../../helper/files";

const initForm = {
  code: "",
  order: "",
  ru: {
    name: "",
    name_one: "",
    description: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
    meta_title_sold: "",
    meta_description_sold: "",
    meta_keywords_sold: "",
    seo_text_short: "",
    seo_text_full: "",
  },
  en: {
    name: "",
    name_one: "",
    description: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
    meta_title_sold: "",
    meta_description_sold: "",
    meta_keywords_sold: "",
    seo_text_short: "",
    seo_text_full: "",
  },
  kaz: {
    name: "",
    name_one: "",
    description: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
    meta_title_sold: "",
    meta_description_sold: "",
    meta_keywords_sold: "",
    seo_text_short: "",
    seo_text_full: "",
  },
  settings: {
    is_hidden: false
  },
  files: [],
  product_ids: [],
}

class Collection extends Component {
  constructor(props) {
    super(props);

    const collectionId = props?.match?.params?.id;

    this.state = {
      form: {...initForm},
      activeTab: queryString.parse(props.location.search)?.content || "main-info",

      collectionId,
      isEditProduct: Boolean(collectionId),
      isLoadProduct: Boolean(collectionId),
      isErrorLoadProduct: false
    };

    this.refFormik = React.createRef();
  }

  componentDidMount = async () => {
    await this.getCollection();
  }

  getCollection = async () => {
    const { collectionId, isEditProduct } = this.state;
    if (!isEditProduct) {
      return
    }

    const res = await agent.get(`/api/collections/${ collectionId }?locale=ru`).then((res) => {
      return res?.data?.data
    }).catch(() => {
      return null
    });
    if (!res) {
      this.setState({
        isLoadProduct: false,
        isErrorLoadProduct: true
      })
      return
    }

    const localeRu = (res?.translations || []).find((t) => t.locale === "ru") || {};
    delete localeRu.id;
    delete localeRu.locale;
    delete localeRu.created_at;
    delete localeRu.updated_at;
    delete localeRu.collection_id;

    const localeEn = (res?.translations || []).find((t) => t.locale === "en") || {};
    delete localeEn.id;
    delete localeEn.locale;
    delete localeEn.created_at;
    delete localeEn.updated_at;
    delete localeEn.collection_id;

    const localeKaz = (res?.translations || []).find((t) => t.locale === "kaz") || {};
    delete localeKaz.id;
    delete localeKaz.locale;
    delete localeKaz.created_at;
    delete localeKaz.updated_at;
    delete localeKaz.collection_id;

    const files = (res?.files || [])
      .sort((a, b) => {
        if (a?.order > b?.order) {
          return 1
        }
        if (a?.order < b?.order) {
          return -1
        }
        return 0
      })
      .map((t) => {
        return {
          ...t,
          fileType: t.type
        }
      })

    const form = {
      code: res?.code,
      order: res?.order,
      ru: localeRu,
      en: localeEn,
      kaz: localeKaz,
      settings: {
        is_hidden: Boolean(Number(res?.settings?.is_hidden || 0))
      },
      files: files,
      product: res?.product || [],
      product_ids: res?.product_ids || [],
    }
    this.setState({
      form,
      isLoadProduct: false
    })
  }

  submitCollection = async () => {
    if (this.state.isEditProduct) {
      await this.submitCollectionEdit();
    }
    if (!this.state.isEditProduct) {
      await this.submitCollectionCreate();
    }
  }
  submitCollectionEdit = async () => {
    this.setState({ isBackdrop: true });
    const { collectionId } = this.state;
    const values = await this._getBody();
    const res = await agent.put(`/api/collections/${ collectionId }`, values).then((res) => {
      return res.data
    }).catch((err) => {
      const message = "Ошибка сервера";
      return {error: message}
    });
    if (res.error) {
      this.setState({ isBackdrop: false });
      Notification({
        type: NotificationTypes.error,
        message: res.error
      })
      return
    }
    this.setState({ isBackdrop: false });
    Notification({
      type: NotificationTypes.success,
      message: "Коллекция успешно обновлена"
    });
    this.props.history.push('/collections')
  }
  submitCollectionCreate = async () => {
    this.setState({ isBackdrop: true });
    const values = await this._getBody();
    const res = await agent.post(`/api/collections`, values).then((res) => {
      return res.data
    }).catch((err) => {
      const message = "Ошибка сервера";
      return {error: message}
    });
    if (res.error) {
      this.setState({ isBackdrop: false });
      Notification({
        type: NotificationTypes.error,
        message: res.error
      })
      return
    }
    this.setState({ isBackdrop: false });
    Notification({
      type: NotificationTypes.success,
      message: "Коллекция успешно обновлена"
    });
    this.props.history.push('/collections')
  }
  _getBody = async () => {
    let values = this.refFormik.current.values;

    values.product_ids = (values.product_ids || []).filter((t) => !!t);

    let files = [];
    let fileIndex = 0;
    for (const file of values.files) {
      if (file.id) {
        const fileId = await changeFileSort(file.id, fileIndex);
        files.push(file.id);
      } else {
        const fileId = await uploadFile(file.file, {
          type: file.fileType,
          order: fileIndex
        });
        files.push(fileId);
      }
      fileIndex = fileIndex + 1;
    }
    values.file_ids = files;

    return values
  }

  changeValues = (values) => {
    if (!this.refFormik.current) {
      return
    }
    this.refFormik.current.setValues(values);
  }

  changeActiveTab = (activeTab) => {
    const currentUrl = new URL(window.location.href);
    let currentSearch = new URLSearchParams(currentUrl.search);
    currentSearch.set("content", activeTab);
    const search = Boolean(currentSearch) ? `?${currentSearch}` : ``;
    const reloadUrl = `${window?.location?.protocol || "https:"}//${window.location.host}${window.location.pathname}${search}`
    window.history.replaceState(null, null, reloadUrl);

    this.setState({
      activeTab
    })
  }

  handleSubmit = () => {
    this.refFormik.current.handleSubmit();
  }

  render() {
    const {
      form,
      activeTab,

      isBackdrop,
      isEditProduct,
      isLoadProduct,
      isErrorLoadProduct
    } = this.state;

    if (!!isLoadProduct) {
      return <LoadingContent/>
    }
    if (!!isErrorLoadProduct) {
      return <ErrorContent/>
    }
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between" mb={1} wrap="nowrap" sx={{width: "100%"}}>
          <Grid item>
            <Typography variant="h1">
              {isEditProduct ? 'Редактирование коллекции' : 'Создание коллекции'}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button onClick={this.handleSubmit} variant="contained" color="primary" size="small">
                  <SaveIcon sx={{marginRight: "5px"}}/>
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <NavigationMenu
              tab={activeTab}
              onChange={this.changeActiveTab}
            />
          </Grid>
          <Grid item xs={10}>
            <Formik
              innerRef={this.refFormik}
              initialValues={form}
              validationSchema={validationSchema}
              onSubmit={this.submitCollection}
            >{(props) => {
              const {
                values,
                errors,
                touched
              } = props;

              return (
                <>
                  <VisibleContent visible={Boolean(activeTab === 'main-info')}>
                    <MainInformation
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'name-product')}>
                    <NameSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'seo-meta')}>
                    <SeoMeta
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'product-description')}>
                    <ProductDescription
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'product-ids')}>
                    <ProductIds
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'images')}>
                    <Images
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                </>
              )
            }}</Formik>
          </Grid>
        </Grid>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </>
    );
  }
}
const ErrorContent = React.memo(() => {
  return (
    <Box bgcolor="white" borderRadius="10px" padding="124px">
      <Typography variant="h1" color="error" align="center">E R R O R<br/>4 0 4</Typography>
      <Typography variant="h4" color="error" align="center" mt={2}>
        Запрашиваемая<br/>коллекций не найден
      </Typography>
    </Box>
  )
});
const LoadingContent = React.memo(() => {
  return (
    <Box bgcolor="white" borderRadius="10px" padding="124px">
      <Typography variant="h1" color="secondary" align="center">L O A D I N G</Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <CircularProgress color="secondary"/>
      </Box>
    </Box>
  )
});
const VisibleContent = (props) => {
  const {visible, children} = props;
  if (!visible) {
    return null
  }
  return (
    <Box display={!visible ? 'none' : 'block'}>
      {children}
    </Box>
  )
}

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Заполните поле'),
  order: Yup.string().required('Заполните поле'),
  ru: Yup.object().shape({
    name: Yup.string().required('Заполните поле'),
  }),
  product_ids: Yup.array(Yup.string().required()).min(1, 'Заполните поле').required('Заполните поле')
});

export default Collection
