import React, {Component} from "react";
import {
  Box,
  Grid,
  Typography,
  Backdrop,
  CircularProgress
} from "@mui/material";
import {
  Table,
  Filters,
  ImportExport
} from "./components";
import {
  DialogConfirmAction
} from "../../../../components";
import agent from "../../../../agent/agent";
import queryString from "query-string";

const initFilter = {
  "filters[name]": "",
  "filters[code]": "",
  page: 1,
  per_page: 20
}

class Stones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],

      filter: {...initFilter},
      pagination: {
        totalPages: 1,
        totalItems: 0,
      },

      isLoadingItems: true,
      isBackdrop: false,
    };

    this.refDialogConfirmAction = React.createRef();
  }

  componentDidMount = async () => {
    await this.parseFilterUrl();
    await this.getItems();
  }

  getItems = async () => {
    this.setState({
      items: [],
      isLoadingItems: true
    })
    const filter = this._filterApi();
    const res = await agent.get(`/api/stones?${filter}`, {
      params: {
        locale: "ru"
      }
    }).then((res) => {
      return res
    }).catch(() => {
      return
    })
    this.setState({
      items: (res.data?.data || []),
      pagination: {
        totalItems: res?.data?.meta?.total || 0,
        totalPages: res?.data?.meta?.last_page || 0
      },
      isLoadingItems: false
    });
    this.setFilterUrl();
  }
  deleteItem = async (item, confirm = false) => {
    if (!confirm) {
      this.refDialogConfirmAction.current.open({
        title: "Подтверждение",
        message: `Вы действительно хотите удалить камень <span style="font-weight: 600">"${item.name}"</span>?`,

        onSuccess: this.deleteItem.bind(this, item, true)
      })

      return
    }

    this.setState({ isBackdrop: true });
    await agent.delete(`/api/stones/${item?.id}`).then((res) => {
      return true
    }).catch(() => {
      return false
    });
    await this.getItems();
    this.setState({ isBackdrop: false });
  }

  // Логика с фильтрами
  setFilterUrl = () => {
    const filterString = this._filterApi();

    const pathname = window?.location?.pathname || "";
    const newPathname = [pathname, filterString].filter((t) => !!t).join("?");

    window.history.replaceState(null, null, newPathname);
  }
  parseFilterUrl = () => {
    const locationSearch = this.props?.location?.search || "";
    let filter = queryString.parse(locationSearch, {
      arrayFormat: "bracket"
    });
    this.setState({
      filter: {
        ...initFilter,
        ...filter
      }
    });
  }
  changeFilter = async (filter, isFastStart) => {
    await this.setState({ filter });
    if (!isFastStart) {
      return
    }
    await this.getItems();
  }
  resetFilter = async () => {
    await this.setState({filter: {...initFilter}});
    await this.getItems();
  }
  _filterApi = () => {
    const filter = {...this.state.filter};

    let list = [];
    Object.keys(filter).map((filterKey) => {
      const value = filter?.[filterKey];
      if (!!value) {
        list.push(`${filterKey}=${value}`);
      }
    })

    return list.join("&")
  }

  render() {
    const {
      items,
      filter,
      pagination,
      isLoadingItems,
      isBackdrop
    } = this.state;

    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between" mb={2}>
          <Grid item>
            <Typography variant="h1">Камни</Typography>
          </Grid>
          <Grid item>
            <ImportExport/>
          </Grid>
        </Grid>

        <Filters
          filter={filter}
          onChange={this.changeFilter}
          onSearch={this.getItems}
          onReset={this.resetFilter}
        />

        <Box mt={2}/>

        <Table
          data={items}
          filter={filter}
          totalPage={pagination.totalPages}
          isLoad={isLoadingItems}
          onChange={this.changeFilter}
          onDelete={this.deleteItem}
        />

        <DialogConfirmAction
          ref={this.refDialogConfirmAction}
        />

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

      </>
    );
  }
}

export default Stones
