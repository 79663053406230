import {palette} from "../common";

export default {
  styleOverrides: {

    root: {
      fontSize: 18,
      lineHeight: "26px",
      fontWeight: "600",
      height: 45,
      borderRadius: 0,
      boxShadow: "none"
    },

    contained: {
      border: "1px solid",
      borderColor: palette.primary.main,
    },

    outlined: {
      border: "1px solid",
      borderColor: palette.primary.main,
      color: palette.primary.main
    },

    outlinedError: {
      borderColor: palette.error.main,
      color: palette.error.main
    },

    sizeSmall: {
      height: 'initial',
      minHeight: 'initial',
      fontSize: '16px',
      lineHeight: '100%',
      padding: '10px 15px',
      textTransform: 'initial',
    }
  }
}
