import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Grid,
  Tooltip,
  Skeleton,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Edit as EditIcon,
  Delete as DeleteIcon
} from "@mui/icons-material";
import {
  useStore
} from "react-redux";
import {
  Link
} from "react-router-dom"
import moment from "moment";

const TableCustom = (props) => {
  const {
    data,
    filter,
    totalPage,
    isLoad,
    onChange,
    onDelete
  } = props;

  const handleChangePage = (event, newPage) => {
    let newFilter = {...filter};
    newFilter.page = newPage;
    onChange(newFilter, true)
  }

  return (
    <>

      <Grid container justifyContent="flex-end" mb={2}>
        <Grid item><Pagination count={Number.parseFloat(totalPage || 0)} page={Number.parseFloat(filter.page || 0)} onChange={handleChangePage}/></Grid>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Наименование (RU)</TableCell>
            <TableCell>Наименование (EN)</TableCell>
            <TableCell>Наименование (KAZ)</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(isLoad) && <RowLoading/>}
          {data.map((t) => <RowProducts key={`row-products-${t.id}`} product={t} onDelete={onDelete}/>)}
        </TableBody>
      </Table>

      <Grid container justifyContent="flex-end" mt={2}>
        <Grid item><Pagination count={Number.parseFloat(totalPage || 0)} page={Number.parseFloat(filter.page || 0)} onChange={handleChangePage}/></Grid>
      </Grid>

    </>
  )
}
const RowProducts = React.memo((props) => {
  const {
    product,
    onDelete
  } = props;

  const _getFiled = (translations, name, locale) => {
    const translation = (translations || []).find((t) => t.locale === locale);
    return translation?.[name] || "-"
  }

  return (
    <TableRow>
      <TableCell>
        {product.id}
      </TableCell>
      <TableCell>
        {_getFiled(product?.translations, 'name', 'ru')}
      </TableCell>
      <TableCell>
        {_getFiled(product?.translations, 'name', 'en')}
      </TableCell>
      <TableCell>
        {_getFiled(product?.translations, 'name', 'kaz')}
      </TableCell>
      <TableCell>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Tooltip arrow title="Удалить">
              <IconButton color="error" onClick={onDelete.bind(this, product, false)}>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip arrow title="Редактировать">
              <IconButton color="info" component={Link} to={`/mestorozhdeniya/${product.id}`}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
})
const RowLoading = React.memo(() => {
  return (<>
    {Array.apply(null, Array(10)).map((t) => (
      <TableRow key={`row-loading-${t}`}>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
        <TableCell><Skeleton/></TableCell>
      </TableRow>
    ))}
  </>)
})

export default React.memo(TableCustom)
