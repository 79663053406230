import React from "react";
import {
  Box,
  Typography

} from "@mui/material";
import {withStyles} from "@mui/styles";
import {
  ImagesSection
} from "../../../../products/Product/components/Files";

class Images extends React.PureComponent {
  addFile = (type, event) => {
    let files = [];
    Array.from(event?.target?.files).forEach((file) => {
      files.push({
        file,
        fileType: type
      })
    });

    let values = {...this.props.values};
    values.files = [...values.files, ...files];

    this.props.onChange(values);
  }
  deleteFile = (index) => {
    let values = {...this.props.values};
    values.files.splice(index, 1);
    this.props.onChange(values);
  }

  changeFull = (files) => {
    let values = {...this.props.values};
    values.files = files;
    this.props.onChange(values);
  }

  render () {
    const {
      classes,
      values
    } = this.props;

    return (
      <>
        <Box className={classes.whiteSection}>
          <Typography variant="h3" mb={1}>Фотографии</Typography>
          <ImagesSection
            values={(values?.files || [])}
            onChange={this.addFile.bind(this, 'image')}
            onChangeFull={this.changeFull}
          />
        </Box>
      </>
    )
  }
}

const styles = {
  whiteSection: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px 24px",
    boxSizing: "border-box",
  },
};
Images = withStyles(styles)(Images);

export default Images
