import React, {Component} from "react";
import {
  Box,
  Grid,
  Chip,
  Button,
  Divider,
  Backdrop,
  TextField,
  Typography,
  FormHelperText,
  CircularProgress
} from "@mui/material";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import {
  Formik
} from "formik";
import {
  CKEditor
} from "../../../../components";
import * as Yup from "yup";
import agent from "../../../../agent/agent";

const initForm = {
  "code": "",
  "order": "0",
  "ru": {
    "name": "",
    "name_one": "",
    "description": "",

    "seo_text_short": "",
    "seo_text_full": "",

    "meta_title": "",
    "meta_description": "",
    "meta_keywords": "",
    "meta_title_sold": "",
    "meta_description_sold": "",
    "meta_keywords_sold": ""
  },
  "en": {
    "description": "",
    "seo_text_short": "",
    "seo_text_full": "",
    "name_one": "",
    "name": "",
    "meta_title": "",
    "meta_keywords": "",
    "meta_description": "",
    "meta_title_sold": "",
    "meta_description_sold": "",
    "meta_keywords_sold": ""
  },
  "kaz": {
    "description": "",
    "seo_text_short": "",
    "seo_text_full": "",
    "name_one": "",
    "name": "",
    "meta_title": "",
    "meta_keywords": "",
    "meta_description": "",
    "meta_title_sold": "",
    "meta_description_sold": "",
    "meta_keywords_sold": ""
  }
}

class Category extends Component {
  constructor(props) {
    super(props);

    const categoryId = props?.match?.params?.id;

    this.state = {
      category: null,

      categoryId,

      isEdit: Boolean(categoryId),
      isLoad: true,
      isError: false,
      isBackdrop: false
    };
    this.refFormik = React.createRef();
  }

  componentDidMount = async () => {
    await this.getCategory();
  }

  getCategory = async () => {
    if (!this.state.isEdit) {
      this.setState({
        category: {...initForm},
        isLoad: false,
      })
      return
    }

    const response = await agent.get(`/api/categories/${this.state.categoryId}`).then((res) => {
      return res.data?.data || null
    }).catch(() => {
      return null
    });
    if (!response) {
      this.setState({
        isLoad: false,
        isError: true,
      })

      return
    }

    const localeRu = (response?.translations || []).find((t) => t.locale === 'ru') || {...initForm.ru};
    delete localeRu.id;
    delete localeRu.created_at;
    delete localeRu.locale;
    delete localeRu.updated_at;

    const localeEn = (response?.translations || []).find((t) => t.locale === 'en') || {...initForm.en};
    delete localeEn.id;
    delete localeEn.created_at;
    delete localeEn.locale;
    delete localeEn.updated_at;

    const localeKaz = (response?.translations || []).find((t) => t.locale === 'kaz') || {...initForm.kaz};
    delete localeKaz.id;
    delete localeKaz.created_at;
    delete localeKaz.locale;
    delete localeKaz.updated_at;

    const category = {
      "code": response?.code || "",
      "order": response?.order || "0",
      "ru": {...localeRu},
      "en": {...localeEn},
      "kaz": {...localeKaz}
    };

    this.setState({
      category: category,
      isLoad: false,
    });
  }

  onChangeFiled = ({target}) => {
    const {name, value} = target;
    const values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  }
  onChangeLocale = (locale, value) => {
    let values = this.refFormik.current.values;
    values[locale] = value;
    this.refFormik.current.setValues(values)
  }

  onSubmit = async (values) => {
    this.setState({isBackdrop: true});

    const apiAction = Boolean(this.state?.isEdit) ? 'put' : 'post';
    const apiMethod = Boolean(this.state?.isEdit) ? `/api/categories/${this.state.categoryId}` : '/api/categories';

    const body = this._getBodySubmit(values);
    const response = await agent({
      method: apiAction,
      url: apiMethod,
      data: body
    }).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    this.setState({isBackdrop: false});

    if (Boolean(this.state?.isEdit)) {
      Notification({
        message: "Категория успешно изменена",
        type: NotificationTypes.success
      });
      await this.getCategory();
      return
    }
    if (!Boolean(this.state?.isEdit)) {
      Notification({
        message: "Категория успешно создана",
        type: NotificationTypes.success
      });
      this.props.history.push(`/categories/${response?.data?.id}`)
    }
  }
  _getBodySubmit = (values) => {
    values.ru.code = values.code;
    values.en.code = values.code;
    values.kaz.code = values.code;

    return values
  }
  onSave = () => {
    this.refFormik.current.handleSubmit();
  }

  render() {
    const {
      category,
      isEdit,
      isLoad,
      isError,
      isBackdrop
    } = this.state;

    if (isLoad) {
      return <LoadingContent/>
    }
    if (isError) {
      return <ErrorContent/>
    }

    return (
      <>

        <Grid container alignItems="center" justifyContent="space-between" mb={2}>
          <Grid item>
            <Typography variant="h1">
              {isEdit ? 'Редактирование' : 'Создание'}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" size="small" onClick={this.onSave}>
              Сохранить
            </Button>
          </Grid>
        </Grid>

        <Formik
          innerRef={this.refFormik}
          initialValues={category}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
        >{(props) => {
          const {
            values,
            errors,
            touched
          } = props;

          return (
            <>
              <Box borderRadius="10px" bgcolor="white" boxShadow="0 0 3px -1px black" p={2}>
                <Typography variant="h4" mb={2}>Общаяя информация</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      value={values.code}
                      label="Артикул"
                      size="small"
                      name="code"
                      fullWidth
                      error={Boolean(touched?.code && errors?.code)}
                      helperText={touched?.code && errors?.code}
                      onChange={this.onChangeFiled}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      value={values.order}
                      label="Сортировка"
                      size="small"
                      name="order"
                      type="number"
                      fullWidth
                      error={Boolean(touched?.order && errors?.order)}
                      helperText={touched?.order && errors?.order}
                      onChange={this.onChangeFiled}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2}/>

              <TranslateSection
                locale="ru"
                values={values?.ru || {}}
                errors={errors?.ru || {}}
                touched={touched?.ru || {}}
                onChange={this.onChangeLocale.bind(this, 'ru')}
              />

              <Box mt={2}/>

              <TranslateSection
                locale="en"
                values={values?.en || {}}
                errors={errors?.en || {}}
                touched={touched?.en || {}}
                onChange={this.onChangeLocale.bind(this, 'en')}
              />

              <Box mt={2}/>

              <TranslateSection
                locale="kaz"
                values={values?.kaz || {}}
                errors={errors?.kaz || {}}
                touched={touched?.kaz || {}}
                onChange={this.onChangeLocale.bind(this, 'kaz')}
              />
            </>
          )
        }}</Formik>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

      </>
    );
  }
}

const TranslateSection = React.memo((props) => {
  const {
    locale,
    values,
    errors,
    touched,
    onChange
  } = props;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    let newValues = values;
    newValues[name] = value;
    onChange(newValues);
  }

  return (
    <Box borderRadius="10px" bgcolor="white" boxShadow="0 0 3px -1px black" p={2}>
      <Typography variant="h4" mb={2}>Локализация <Chip label={String(locale || '').toUpperCase()} size="small"/></Typography>

      <Typography variant="h5" mb={1.5}>Основная информация</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            value={values.name}
            name="name"
            label="Наименование"
            placeholder="..."
            size="small"
            fullWidth
            error={Boolean(touched?.name && errors?.name)}
            helperText={touched?.name && errors?.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={values.name_one}
            name="name_one"
            label="Наименование в единственном числе"
            placeholder="..."
            size="small"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CKEditor
            label="Описание"
            value={values.description}
            name="description"
            placeholder="..."
            size="small"
            fullWidth
            onChange={handleChange}
          />
          {Boolean(touched?.description && errors?.description) && (
            <FormHelperText error={true}>
              {touched?.description && errors?.description}
            </FormHelperText>
          )}
        </Grid>
      </Grid>

      <Box my={3}><Divider/></Box>

      <Grid container spacing={3} wrap="nowrap">
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CKEditor
                value={values.seo_text_full}
                name="seo_text_full"
                label="SEO описание"
                placeholder="..."
                size="small"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <CKEditor
                value={values.seo_text_short}
                name="seo_text_short"
                label="SEO короткое описание"
                placeholder="..."
                size="small"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider orientation="vertical"/>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                value={values.meta_title}
                name="meta_title"
                label="meta_title"
                placeholder="..."
                size="small"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={values.meta_title_sold}
                name="meta_title_sold"
                label="meta_title_sold"
                placeholder="..."
                size="small"
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={values.meta_description}
                name="meta_description"
                label="meta_description"
                placeholder="..."
                size="small"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={values.meta_description_sold}
                name="meta_description_sold"
                label="meta_description_sold"
                placeholder="..."
                size="small"
                fullWidth
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                value={values.meta_keywords}
                name="meta_keywords"
                label="meta_keywords"
                placeholder="..."
                size="small"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={values.meta_keywords_sold}
                name="meta_keywords_sold"
                label="meta_keywords_sold"
                placeholder="..."
                size="small"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
})
const ErrorContent = React.memo(() => {
  return (
    <Box bgcolor="white" borderRadius="10px" padding="124px">
      <Typography variant="h1" color="error" align="center">E R R O R<br/>4 0 4</Typography>
      <Typography variant="h4" color="error" align="center" mt={2}>
        Запрашиваемый<br/>метал не найден
      </Typography>
    </Box>
  )
});
const LoadingContent = React.memo(() => {
  return (
    <Box bgcolor="white" borderRadius="10px" padding="124px">
      <Typography variant="h1" color="secondary" align="center">L O A D I N G</Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <CircularProgress color="secondary"/>
      </Box>
    </Box>
  )
});

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Заполните поле'),
  ru: Yup.object().shape({
    "name": Yup.string().required('Заполните поле'),
    "description": Yup.string().required('Заполните поле'),
  }),
  // en: Yup.object().shape({
  //   "name": Yup.string().required('Заполните поле'),
  //   "description": Yup.string().required('Заполните поле'),
  // }),
  // kaz: Yup.object().shape({
  //   "name": Yup.string().required('Заполните поле'),
  //   "description": Yup.string().required('Заполните поле'),
  // }),
});

export default Category
