import React from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  Pagination,
  Typography,
  FormHelperText,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  AutocompleteProducts
} from "../../../../../../components";

const paginationLimit = 10;
class ProductIds extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      currentPage: 1,

      isOpenCreate: false
    };
  }

  addProductId = () => {
    let values = {...this.props.values};
    values.product_ids.push("");
    this.props.onChange(values);
  }
  deleteProductId = (productId) => {
    let values = {...this.props.values};
    const index = (values.product_ids || []).findIndex((t) => t === productId);
    values.product_ids.splice(index, 1);
    this.props.onChange(values);
  }
  changeProductId = (initProductId, event) => {
    const productId = event?.target?.value;
    let values = {...this.props.values};
    const index = (values.product_ids || []).findIndex((t) => t === initProductId);
    values.product_ids[index] = productId;
    this.props.onChange(values);
  }

  openAddPopUp = () => {
    this.setState({isOpenCreate: true})
  }
  closeAddPopUp = () => {
    this.setState({isOpenCreate: false})
  }
  createAddPopUp = (val) => {
    let values = {...this.props.values};
    values.product_ids.push(val);
    this.props.onChange(values);
    this.setState({isOpenCreate: false})
  }

  _productItems = () => {
    const { values } = this.props;
    const { search, currentPage } = this.state;

    const product = values.product;
    let product_ids = (values?.product_ids || []);
    if (search.length > 0) {
      const searchLow = (search || "").toLowerCase();
      product_ids = product.filter((t) => {
        const code = (t?.code || "").toLowerCase();
        const name = (t?.name || "").toLowerCase();
        const sku = (t?.name || "").toLowerCase();
        return Boolean(
          code.indexOf(searchLow) > -1 ||
          name.indexOf(searchLow) > -1 ||
          sku.indexOf(searchLow) > -1
        )
      }).map((t) => t.id)
    }

    const strSplice = (currentPage - 1) * paginationLimit;
    const endSplice = (currentPage) * paginationLimit;
    return [...product_ids].splice(strSplice, endSplice)
  }

  render () {
    const {
      values,
      errors,
      touched,
      classes
    } = this.props;
    const {
      search,
      currentPage,
      isOpenCreate
    } = this.state;

    return (
      <Box className={classes.whiteSection}>
        <Grid container justifyContent="space-between" mb={2}>
          <Grid item>
            <TextField
              value={search}
              label="Поиск по названию"
              size="small"
              onChange={({target}) => this.setState({search: target.value})}
            />
          </Grid>
          <Grid item>
            <Pagination
              page={currentPage}
              count={Math.ceil(values.product_ids.length / paginationLimit)}
              onChange={(e, currentPage) => this.setState({currentPage})}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {this._productItems().map((productId, index) => (
            <Grid key={`CustomRowProduct-${ productId }`} item xs={12}>
              <CustomRowProduct
                value={productId}
                onDelete={this.deleteProductId.bind(this, productId)}
                onChange={this.changeProductId.bind(this, productId)}
              />
            </Grid>
          ))}

          <Grid item xs={12}>
            <Button
              fullWidth
              size="small"
              variant="contained"
              onClick={this.openAddPopUp}
            >
              Добавить товар
            </Button>
          </Grid>

          {Boolean(errors.product_ids && touched.product_ids) && (
            <Grid item xs={12}>
              <FormHelperText error={errors.product_ids && touched.product_ids}>
                {touched.product_ids && errors.product_ids}
              </FormHelperText>
            </Grid>
          )}
        </Grid>

        <Grid container justifyContent="flex-end" mt={2}>
          <Grid item>
            <Pagination
              page={currentPage}
              count={Math.ceil(values.product_ids.length / paginationLimit)}
              onChange={(e, currentPage) => this.setState({currentPage})}
            />
          </Grid>
        </Grid>

        <DialogAddProduct
          open={isOpenCreate}
          onClose={this.closeAddPopUp}
          onCreate={this.createAddPopUp}
        />
      </Box>
    )
  }
}
const CustomRowProduct = React.memo((props) => {
  const {
    value,
    onDelete,
    onChange
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <AutocompleteProducts
          value={value}
          size="small"
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          fullWidth
          size="small"
          variant="outlined"
          onClick={onDelete}
        >
          Удалить
        </Button>
      </Grid>
    </Grid>
  )
});
const DialogAddProduct = React.memo((props) => {
  const {
    open,
    onClose,
    onCreate
  } = props;
  const [value, setValue] = React.useState("");

  const handleChange = ({ target }) => {
    const { value: newVal } = target;
    onCreate(newVal);
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      disablePortal={true}
      PaperProps={{sx: { overflow: "initial" }}}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography variant="h3">Добавление товара</Typography>
      </DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <AutocompleteProducts
            value={value}
            size="small"
            onChange={handleChange}
          />
        </Box>
        <Grid container justifyContent="flex-end" mt={2}>
          <Grid item>
            <Button variant="outlined" size="small" onClick={onClose}>
              Закрыть
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
})

const styles = {
  whiteSection: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px 24px",
    boxSizing: "border-box",
  },
};
ProductIds = withStyles(styles)(ProductIds);

export default ProductIds
