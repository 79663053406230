import agent from "../agent/agent";

const UPDATE_USER = 'app/UPDATE_USER'
const UPDATE_USER_ROLE = 'app/UPDATE_USER_ROLE'
const GET_COUNTRY = 'app/GET_COUNTRY'
const GET_USERS = 'app/GET_USERS'
const GET_SERVICES = 'app/GET_SERVICES'
const SET_CURRENT_BUILD_VERSION = 'app/SET_CURRENT_BUILD_VERSION'

const initialState = {
    user: null,
    userRole: "",

    country: [],
    users: [],
    services: [],

    currentBuildVersion: null
};

export function setUser(user) {
    return async function (dispatch) {
        dispatch({
            type: UPDATE_USER,
            user
        });
        dispatch(setUserRole(user));
    }
}
export function setCurrentBuildVersion(currentBuildVersion) {
    return {
        type: SET_CURRENT_BUILD_VERSION,
        currentBuildVersion
    }
}
export function setUserRole(user) {
    return async function (dispatch) {
        const userId = user?.id;

        const userRoles = await agent.get(`/admin/api-admin-user/${userId}?expand=groups`).then((res) => {
            return res.data?.groups || []
        }).catch(() => {
            return []
        });
        const isSupportService = Boolean(userRoles.find((t) => t.name === "Support Service"));

        dispatch({
            type: UPDATE_USER_ROLE,
            value: Boolean(isSupportService) ? "Support Service" : "Administrator"
        })
    }
}
export function getCountry() {
    return async function (dispatch) {

        const country = await agent.get('/api/country').then((res) => {
            return res.data
        }).catch(() => {
            return []
        })

        dispatch({
            type: GET_COUNTRY,
            country
        })

    }
}
export function getServices() {

    return async function (dispatch) {

        const services = await agent.get('/api/service/get-all').then((res) => {
            return res.data
        }).catch(() => {
            return []
        })

        dispatch({
            type: GET_SERVICES,
            services
        })

    }
}
export function getUsers() {

    return async function (dispatch) {

        // const users = await agent.get('/smsgoapi/gousers?per-page=9999').then((res) => {
        //     return res.data
        // }).catch(() => {
        //     return []
        // })
        //
        // dispatch({
        //     type: GET_USERS,
        //     users
        // })

    }
}

export default function AppState(state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_USER: {
            let user = action.user

            return {
                ...state,
                user
            }
        }
        case UPDATE_USER_ROLE: {
            let userRole = action.value;

            return {
                ...state,
                userRole
            }
        }
        case GET_COUNTRY: {
            let country = action.country

            return {
                ...state,
                country
            }
        }
        case GET_USERS: {
            let users = action.users

            return {
                ...state,
                users
            }
        }
        case GET_SERVICES: {
            let services = action.services

            return {
                ...state,
                services
            }
        }
        case SET_CURRENT_BUILD_VERSION: {
            let currentBuildVersion = action.currentBuildVersion

            return {
                ...state,
                currentBuildVersion
            }
        }
        default:
            return state;
    }
}
