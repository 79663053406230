import React, {Component} from "react";
import {Backdrop, Box, Button, CircularProgress, Grid, Typography} from "@mui/material";
import {Delete as DeleteIcon, Save as SaveIcon} from "@mui/icons-material";
import {
  AdditionalInformation as AdditionalInformationSection,
  Certificates as CertificatesSection,
  Collections as CollectionsSection,
  Files as FilesSection,
  Guarantee as GuaranteeSection,
  Headsets as HeadsetsSection,
  Images as ImagesSection,
  Inserts as InsertsSection,
  MainInfo as MainInfoSection,
  NameProduct as NameProductSection,
  NavigationMenu as NavigationMenuComponent,
  Prices as PricesSection,
  ProductDescription as ProductDescriptionSection,
  SeoMeta as SeoMetaSection,
  StoneMetal as StoneMetalSection,
  StoneSection,
  Videos as VideosSection,
  VipInformation as VipInformationSection
} from "./components";
import {Formik} from "formik";
import {DialogConfirmAction} from "../../../../components";
import {Notification, NotificationTypes} from "../../../../common/Notification";
import * as Yup from "yup";
import queryString from "query-string";
import agent from "../../../../agent/agent";
import {changeFileSort, uploadFile} from "../../../../helper/files";
import {SectionSingleCheckBox} from "./components/MainInfo";

const initForm = {
  "code": "",
  "sku": "",
  "gender": "",
  "order": "",
  "category_id": "",
  "size": "",
  "weight": "",

  "is_stone": 0,
  "is_premium": 0,

  "stone_id": "",
  "metal_ids": [],
  "file_ids": [],
  "videos": [],
  "inserts": [],
  "product_ids": [],
  "collection_ids": [],
  "ru": {
    "name": "",
    "name_one": "",
    "name_feed": "",
    "description": "",
    "locale_code": "",
    "short_description": "",
    "discount_name": "",
    "discount_description": "",
    "guarantee_text": "",
    "preview_text": "",
    "meta_title": "",
    "meta_keywords": "",
    "meta_description": "",
    "price": "",
    "old_price": "",
    "currency": "",
    "is_hidden": false,
    "is_novelty": true,
    "is_sold": false,
    "is_price_visible": true
  },
  "en": {
    "name": "",
    "name_one": "",
    "name_feed": "",
    "locale_code": "",
    "description": "",
    "short_description": "",
    "discount_name": "",
    "discount_description": "",
    "guarantee_text": "",
    "preview_text": "",
    "meta_title": "",
    "meta_keywords": "",
    "meta_description": "",
    "price": "",
    "old_price": "",
    "currency": "",
    "is_hidden": false,
    "is_novelty": true,
    "is_sold": false,
    "is_price_visible": true
  },
  "kaz": {
    "name": "",
    "name_one": "",
    "name_feed": "",
    "locale_code": "",
    "description": "",
    "short_description": "",
    "discount_name": "",
    "discount_description": "",
    "guarantee_text": "",
    "preview_text": "",
    "meta_title": "",
    "meta_keywords": "",
    "meta_description": "",
    "price": "",
    "old_price": "",
    "currency": "",
    "is_hidden": false,
    "is_novelty": true,
    "is_sold": false,
    "is_price_visible": true
  },

  "premium_data": {
    "premium_collection_first_file_id": null,
    "premium_collection_second_file_id": null,
    "premium_central_stone_field_mobile_file_id": null,
    "premium_certificate_title_file_id": null,
    "premium_stone_file_id": null,
    "premium_background_first_file_id": null,
    "premium_background_second_file_id": null,
    "premium_background_third_file_id": null,
    "premium_background_mobile_file_id": null,
    "premium_insert_mobile_file_id": null,
    "premium_insert_file_id": null,
    "premium_central_stone_size": "",
    "premium_central_stone_purity": "",
    "premium_certificate_title": "",
    "ru": {
      "premium_central_stone_title": "",
      "premium_central_stone_description": "",
      "premium_central_stone_field_description": "",
      "premium_central_stone_color": "",
      "premium_history_description": "",
      "premium_additional_description": "",
      "premium_collection_description": "",
      "premium_stone_description": "",
    },
    "en": {
      "premium_central_stone_title": "",
      "premium_central_stone_description": "",
      "premium_central_stone_field_description": "",
      "premium_central_stone_color": "",
      "premium_history_description": "",
      "premium_additional_description": "",
      "premium_collection_description": ""
    },
    "kaz": {
      "premium_central_stone_title": "",
      "premium_central_stone_description": "",
      "premium_central_stone_field_description": "",
      "premium_central_stone_color": "",
      "premium_history_description": "",
      "premium_additional_description": "",
      "premium_collection_description": ""
    },
  },
  "stone_data": {
    "stone_background_of_the_gemological_description_file_id": null,
    "stone_version_file_ids": [],
    "stone_rim_background_file_ids": [],
    "stone_certificate_file_id": null,
    "stone_field_file_id": null,

    "ru": {
      "stone_origin": "",
      "stone_size": "",
      "stone_form": "",
      "stone_gentrification": "",
      "stone_transparency": "",
      "stone_cut_type": "",
      "stone_color": "",
      "stone_field": "",
      "stone_certificate": "",
    },
    "en": {
      "stone_origin": "",
      "stone_size": "",
      "stone_form": "",
      "stone_gentrification": "",
      "stone_transparency": "",
      "stone_cut_type": "",
      "stone_color": "",
      "stone_field": "",
      "stone_certificate": "",
    },
    "kaz": {
      "stone_origin": "",
      "stone_size": "",
      "stone_form": "",
      "stone_gentrification": "",
      "stone_transparency": "",
      "stone_cut_type": "",
      "stone_color": "",
      "stone_field": "",
      "stone_certificate": "",
    },
  }
}

class Product extends Component {
  constructor(props) {
    super(props);

    const editProductId = props?.match?.params?.id || "";

    this.state = {
      product: JSON.parse(JSON.stringify({...initForm})),
      isProductVip: false,
      isProductStone: false,

      productId: editProductId,
      activeTab: queryString.parse(props.location.search)?.content || "main-info",

      isLoadProduct: Boolean(editProductId),
      isEditProduct: Boolean(editProductId),
      isErrorLoadProduct: false
    };

    this.refFormik = React.createRef();
    this.refDialogConfirmAction = React.createRef();
  }

  componentDidMount = async () => {
    await this.getProduct();
  }

  getProduct = async () => {
    if (!this.state.productId) {
      return
    }

    const initProduct = await agent.get(`/api/products/${this.state.productId}?locale=ru`).then((res) => {
      return res.data?.data || null
    }).catch(() => {
      return null
    });
    if (!initProduct) {
      this.setState({
        isLoadProduct: false,
        isErrorLoadProduct: true
      })
      return
    }

    const inserts = (initProduct?.inserts || []).map((insert) => {
      const ru = (insert?.translations || []).find((t) => t.locale === 'ru') || {};
      const en = (insert?.translations || []).find((t) => t.locale === 'en') || {};
      const kaz = (insert?.translations || []).find((t) => t.locale === 'kaz') || {};

      return {
        ...insert,
        country_ids: (insert?.countries || []).map((t) => t.id),
        ru,
        en,
        kaz,
      }
    });
    const file_ids = (initProduct?.files || [])
      .sort((a, b) => {
        if (a?.order > b?.order) {
          return 1
        }
        if (a?.order < b?.order) {
          return -1
        }
        return 0
      })
      .map((t) => {
      return {
        ...t,
        fileType: t.type
      }
    })

    const getPricesLocale = function (locale) {
      let price = (initProduct?.translations || []).find((t) => t.locale === locale)?.price || 0;
      let oldPrice = (initProduct?.translations || []).find((t) => t.locale === locale)?.old_price || 0;
      if (!price && (initProduct?.products || []).length > 0) {
        (initProduct?.products || []).map((product) => {
          price = price + Number.parseFloat((product?.translations || []).find((t) => t.locale === locale)?.price || 0);
          oldPrice = oldPrice + Number.parseFloat((product?.translations || []).find((t) => t.locale === locale)?.old_price || 0);
        })
      };
      return {
        price,
        old_price: oldPrice
      }
    };
    const priceEn = getPricesLocale('en');
    const priceRu = getPricesLocale('ru');
    const priceKaz = getPricesLocale('kaz');

    let ru = (initProduct?.translations || []).find((t) => t.locale === 'ru') || {};
    ru = {...initForm?.ru, ...ru, ...priceRu}

    let en = (initProduct?.translations || []).find((t) => t.locale === 'en') || {};
    en = {...initForm?.en, ...en, ...priceEn}

    let kaz = (initProduct?.translations || []).find((t) => t.locale === 'kaz') || {};
    kaz = {...initForm?.kaz, ...kaz, ...priceKaz}

    const product = {
      ...JSON.parse(JSON.stringify({...initForm})),
      ...initProduct,
      ru: ru,
      en: en,
      kaz: kaz,
      metal_ids: (initProduct?.metals || []).map((t) => t.id),
      collection_ids: (initProduct?.collections || []).map((t) => t.id),
      videos: (initProduct?.videos || []).map((t) => t.url),
      inserts,
      file_ids,
      "premium_data": {
        "premium_collection_first_file_id": initProduct?.premiumCollectionFirstFile || null,
        "premium_collection_second_file_id": initProduct?.premiumCollectionSecondFile || null,
        "premium_central_stone_field_mobile_file_id": initProduct?.premiumCentralStoneFieldMobileFile || null,
        "premium_certificate_title_file_id": initProduct?.premiumCertificateTitleFile || null,
        "premium_stone_file_id": initProduct?.premiumStoneFile || null,
        "premium_background_first_file_id": initProduct?.premiumBackgroundFirstFile || null,
        "premium_background_second_file_id": initProduct?.premiumBackgroundSecondFile || null,
        "premium_background_third_file_id": initProduct?.premiumBackgroundThirdFile || null,
        "premium_background_mobile_file_id": initProduct?.premiumBackgroundMobileFile || null,
        "premium_insert_mobile_file_id": initProduct?.premiumInsertMobileFile || null,
        "premium_insert_file_id": initProduct?.premiumInsertFile || null,
        "premium_central_stone_size": initProduct?.premium_central_stone_size || "",
        "premium_central_stone_purity": initProduct?.premium_central_stone_purity || "",
        "premium_certificate_title": initProduct?.premium_certificate_title || "",
        "ru": {
          "premium_central_stone_title": (initProduct?.translations || []).find((t) => t.locale === "ru")?.premium_central_stone_title || "",
          "premium_central_stone_description": (initProduct?.translations || []).find((t) => t.locale === "ru")?.premium_central_stone_description || "",
          "premium_central_stone_field_description": (initProduct?.translations || []).find((t) => t.locale === "ru")?.premium_central_stone_field_description || "",
          "premium_central_stone_color": (initProduct?.translations || []).find((t) => t.locale === "ru")?.premium_central_stone_color || "",
          "premium_history_description": (initProduct?.translations || []).find((t) => t.locale === "ru")?.premium_history_description || "",
          "premium_additional_description": (initProduct?.translations || []).find((t) => t.locale === "ru")?.premium_additional_description || "",
          "premium_collection_description": (initProduct?.translations || []).find((t) => t.locale === "ru")?.premium_collection_description || ""
        },
        "en": {
          "premium_central_stone_title": (initProduct?.translations || []).find((t) => t.locale === "en")?.premium_central_stone_title || "",
          "premium_central_stone_description": (initProduct?.translations || []).find((t) => t.locale === "en")?.premium_central_stone_description || "",
          "premium_central_stone_field_description": (initProduct?.translations || []).find((t) => t.locale === "en")?.premium_central_stone_field_description || "",
          "premium_central_stone_color": (initProduct?.translations || []).find((t) => t.locale === "en")?.premium_central_stone_color || "",
          "premium_history_description": (initProduct?.translations || []).find((t) => t.locale === "en")?.premium_history_description || "",
          "premium_additional_description": (initProduct?.translations || []).find((t) => t.locale === "en")?.premium_additional_description || "",
          "premium_collection_description": (initProduct?.translations || []).find((t) => t.locale === "en")?.premium_collection_description || ""
        },
        "kaz": {
          "premium_central_stone_title": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.premium_central_stone_title || "",
          "premium_central_stone_description": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.premium_central_stone_description || "",
          "premium_central_stone_field_description": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.premium_central_stone_field_description || "",
          "premium_central_stone_color": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.premium_central_stone_color || "",
          "premium_history_description": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.premium_history_description || "",
          "premium_additional_description": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.premium_additional_description || "",
          "premium_collection_description": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.premium_collection_description || ""
        },
      },
      "stone_data": {
        "stone_background_of_the_gemological_description_file_id": initProduct?.stoneBackgroundOfTheGemologicalDescriptionFile || null,
        "stone_rim_background_file_ids": initProduct?.stoneRimBackgroundFiles || [],
        "stone_version_file_ids": initProduct?.stoneVersionFiles || [],
        "stone_certificate_file_id": initProduct?.stoneCertificateFile || null,
        "stone_field_file_id": initProduct?.stoneFieldFile || null,
        "stone_certificate": initProduct?.stone_certificate || "",
        "ru": {
          "stone_origin": (initProduct?.translations || []).find((t) => t.locale === "ru")?.stone_origin || "",
          "stone_size": (initProduct?.translations || []).find((t) => t.locale === "ru")?.stone_size || "",
          "stone_form": (initProduct?.translations || []).find((t) => t.locale === "ru")?.stone_form || "",
          "stone_gentrification": (initProduct?.translations || []).find((t) => t.locale === "ru")?.stone_gentrification || "",
          "stone_transparency": (initProduct?.translations || []).find((t) => t.locale === "ru")?.stone_transparency || "",
          "stone_cut_type": (initProduct?.translations || []).find((t) => t.locale === "ru")?.stone_cut_type || "",
          "stone_color": (initProduct?.translations || []).find((t) => t.locale === "ru")?.stone_color || "",
          "stone_field": (initProduct?.translations || []).find((t) => t.locale === "ru")?.stone_field || "",
        },
        "en": {
          "stone_origin": (initProduct?.translations || []).find((t) => t.locale === "en")?.stone_origin || "",
          "stone_size": (initProduct?.translations || []).find((t) => t.locale === "en")?.stone_size || "",
          "stone_form": (initProduct?.translations || []).find((t) => t.locale === "en")?.stone_form || "",
          "stone_gentrification": (initProduct?.translations || []).find((t) => t.locale === "en")?.stone_gentrification || "",
          "stone_transparency": (initProduct?.translations || []).find((t) => t.locale === "en")?.stone_transparency || "",
          "stone_cut_type": (initProduct?.translations || []).find((t) => t.locale === "en")?.stone_cut_type || "",
          "stone_color": (initProduct?.translations || []).find((t) => t.locale === "en")?.stone_color || "",
          "stone_field": (initProduct?.translations || []).find((t) => t.locale === "en")?.stone_field || "",
        },
        "kaz": {
          "stone_origin": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.stone_origin || "",
          "stone_size": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.stone_size || "",
          "stone_form": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.stone_form || "",
          "stone_gentrification": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.stone_gentrification || "",
          "stone_transparency": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.stone_transparency || "",
          "stone_cut_type": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.stone_cut_type || "",
          "stone_color": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.stone_color || "",
          "stone_field": (initProduct?.translations || []).find((t) => t.locale === "kaz")?.stone_field || "",
        },
      }
    };

    delete product.premiumBackgroundFirstFile;
    delete product.premiumCollectionSecondFile;
    delete product.premiumCentralStoneFieldMobileFile;
    delete product.premiumCertificateTitleFile;
    delete product.premiumBackgroundFirstFile;
    delete product.premiumBackgroundSecondFile;
    delete product.premiumBackgroundThirdFile;
    delete product.premiumBackgroundMobileFile;
    delete product.premiumInsertMobileFile;
    delete product.premiumInsertFile;
    delete product.premium_central_stone_size;
    delete product.premium_central_stone_purity;
    delete product.premium_certificate_title;
    delete product.premiumCollectionFirstFile;

    delete product.stoneBackgroundOfTheGemologicalDescriptionFile;
    delete product.stoneBackgroundOfTheGemologicalDescriptionMobileFile;
    delete product.stoneCertificateFile;
    delete product.stoneFieldFile;
    delete product.stoneRimBackgroundFiles;
    delete product.stoneVersionFile;
    delete product.stoneFieldFile;

    this.setState({
      product: product,
      isProductVip: Boolean(product?.is_premium) || Boolean(product?.premium_data?.premium_collection_first_file_id || false),
      isProductStone: Boolean(product?.is_stone) || Boolean(product?.stone_data?.stone_field_file_id || false),
      isLoadProduct: false
    });
  }

  saveProduct = async () => {
    if (this.state.productId) {
      await this.saveProductEdit();
    }
    if (!this.state.productId) {
      await this.saveProductCreate();
    }
  }
  saveProductEdit = async () => {
    this.setState({ isBackdrop: true })

    const body = await this._getBodyProduct();
    const res = await agent.put(`/api/products/${ this.state.productId }`, body).then((res)=> {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    if (res?.error) {
      this.setState({ isBackdrop: false });

      let message = [];
      Object.keys(res?.error?.data?.data || {}).map((errorKey) => {
        const errorValues = res?.error?.data?.data?.[errorKey];
        const errorMessage = Array.isArray(errorValues) ? errorValues.join(", ") : errorValues;
        message.push(errorMessage)
      });

      Notification({
        type: NotificationTypes.error,
        message: message.join(";<br/>") || "Ошибка сервера"
      })

      return
    }

    this.setState({ isBackdrop: false });
    Notification({
      type: NotificationTypes.success,
      message: "Товар успешно изменен"
    });
    this.props.history.push("/products")
  }
  saveProductCreate = async () => {
    this.setState({ isBackdrop: true })

    const isFindProduct = await this.checkProductArticles();
    if (isFindProduct) {
      return
    }

    const body = await this._getBodyProduct();
    const res = await agent.post(`/api/products`, body).then((res)=> {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    if (res?.error) {
      this.setState({ isBackdrop: false });

      let message = [];
      Object.keys(res?.error?.data?.data || {}).map((errorKey) => {
        const errorValues = res?.error?.data?.data?.[errorKey];
        const errorMessage = Array.isArray(errorValues) ? errorValues.join(", ") : errorValues;
        message.push(errorMessage)
      });

      Notification({
        type: NotificationTypes.error,
        message: message.join(";<br/>") || "Ошибка сервера"
      })

      return
    }

    this.setState({ isBackdrop: false });
    Notification({
      type: NotificationTypes.success,
      message: "Товар успешно создан"
    });
    this.props.history.push("/products")
  }
  checkProductArticles = async () => {
    const values = this.refFormik.current.values;
    const sku = values?.sku;
    const ruLocale = values?.ru?.locale_code;
    const enLocale = values?.en?.locale_code;
    const kazLocale = values?.kaz?.locale_code;

    const isFindRu = await agent.get(`/api/products?filters[locale_code]=${ruLocale}&page=1&limit=1`).then((res) => {
      return Boolean((res?.data?.data || []).filter((t) => t.locale_code === ruLocale).length > 0)
    }).catch(() => {
      return false
    });
    const isFindEn = await agent.get(`/api/products?filters[locale_code]=${enLocale || ruLocale}&page=1&limit=1`).then((res) => {
      return Boolean((res?.data?.data || []).filter((t) => t.locale_code === (enLocale || ruLocale)).length > 0)
    }).catch(() => {
      return false
    });
    const isFindKaz = await agent.get(`/api/products?filters[locale_code]=${kazLocale || ruLocale}&page=1&limit=1`).then((res) => {
      return Boolean((res?.data?.data || []).filter((t) => t.locale_code === (kazLocale || ruLocale)).length > 0)
    }).catch(() => {
      return false
    });
    const isFindSku = await agent.get(`/api/products?filters[sku]=${sku}&page=1&limit=1`).then((res) => {
      return Boolean((res?.data?.data || []).length > 0)
    }).catch(() => {
      return false
    });

    const isError = Boolean(isFindRu || isFindEn || isFindKaz || isFindSku);
    if (!isError) {
      return false
    }

    let errors = [];
    if (isFindRu) {
      errors.push('Такой код товара уже есть в Русской версии')
    }
    if (isFindEn) {
      errors.push('Такой код товара уже есть в English версии')
    }
    if (isFindKaz) {
      errors.push('Такой код товара уже есть в Казахстанской версии')
    }
    if (isFindSku) {
      errors.push('Такой идентификационный номер уже существует')
    }
    this.setState({ isBackdrop: false });
    Notification({
      type: NotificationTypes.error,
      message: errors.join(';<br/>')
    });
    return true
  }
  _getBodyProduct = async () => {
    let values = {...this.refFormik.current.values};

    // values.size = Number.parseFloat((values.size || "").replace(",", "."));
    // values.weight = Number.parseFloat((values.weight || "").replace(",", "."));
    values.videos = (values.videos || []).filter((t) => !!t);

    let file_ids = [];
    let fileIndex = 0;
    for (const file of values.file_ids) {
      if (file.id) {
        const fileId = await changeFileSort(file.id, fileIndex);
        file_ids.push(file.id);
      } else {
        const fileId = await uploadFile(file.file, {
          type: file.fileType,
          order: fileIndex
        });
        if (!!fileId) {
          file_ids.push(fileId);
        } else {
          Notification({
            type: NotificationTypes.error,
            message: "Ошибка добавления файла"
          })
        }
      }
      fileIndex = fileIndex + 1;
    }
    values.file_ids = file_ids;

    // Данные премиум товара
    let premium_data = values?.premium_data || {};
    for (const key of Object.keys(premium_data)) {
      const file = premium_data[key];
      if (['premium_central_stone_size', 'premium_central_stone_purity', 'premium_certificate_title', 'ru', 'en', 'kaz'].includes(key)) {
        break;
      }
      if (file?.id) {
        premium_data[key] = file.id;
      } else if (file) {
        premium_data[key] = await uploadFile(file, {type: "image"});
      } else {
        premium_data[key] = null;
      }
    };
    values.premium_data = premium_data;

    // Данные камня товара
    let stone_data = values?.stone_data || {};
    for (const key of Object.keys(stone_data)) {
      const file = stone_data[key];
      if (!['ru', 'en', 'kaz', 'stone_certificate'].includes(key)) {
        if (key === "stone_rim_background_file_ids") {
          let stone_rim_background_file_ids = [];
          for (const key of Object.keys(file)) {
            const _file = file[key];
            let fileId = "";
            if (_file?.id) {
              console.log('_file?.id: ', _file?.id)
              fileId = _file.id;
            }
            else if (_file) {
              fileId = await uploadFile(_file, {type: "image"});
            }
            else {
              fileId = null;
            }
            stone_rim_background_file_ids.push(fileId)
          }
          stone_data[key] = stone_rim_background_file_ids;
        }
        else if (key === "stone_version_file_ids") {
          let stone_version_file_ids = [];
          for (const key of Object.keys(file)) {
            const _file = file[key];
            let fileId = "";
            if (_file?.id) {
              fileId = _file.id;
            }
            else if (_file) {
              fileId = await uploadFile(_file, {type: "image"});
            }
            else {
              fileId = null;
            }
            stone_version_file_ids.push(fileId)
          }
          stone_data[key] = stone_version_file_ids;
        }
        else if (file?.id) {
          stone_data[key] = file.id;
        }
        else if (file) {
          stone_data[key] = await uploadFile(file, {type: "image"});
        }
        else {
          stone_data[key] = null;
        }
      }
    };
    stone_data.stone_background_of_the_gemological_description_mobile_file_id = stone_data.stone_background_of_the_gemological_description_file_id;
    values.stone_data = stone_data;

    values.inserts = (values.inserts || []).map((t) => {
      return {
        ...t,
        country_ids: (t?.country_ids || []).map((a) => a?.id || a)
      }
    })

    // Данные по ценам гарнитура
    const getPricesLocale = function (locale) {
      let price = Boolean((values?.products || []).length > 0) ? 0 : values?.[locale]?.price;
      let oldPrice = Boolean((values?.products || []).length > 0) ? 0 : values?.[locale]?.old_price;
      if (!price && (values?.products || []).length > 0) {
        (values?.products || []).map((product) => {
          price = price + Number.parseFloat((product?.translations || []).find((t) => t.locale === locale)?.price || 0);
          oldPrice = oldPrice + Number.parseFloat((product?.translations || []).find((t) => t.locale === locale)?.old_price || 0);
        })
      };
      return {
        price: Boolean(price <= 0) ? '' : price,
        old_price: Boolean(oldPrice <= 0) ? '' : oldPrice
      }
    };
    values.en = {
      ...values.en,
      ...getPricesLocale('en')
    };
    values.ru = {
      ...values.ru,
      ...getPricesLocale('ru')
    };
    values.kaz = {
      ...values.kaz,
      ...getPricesLocale('kaz')
    };


    if (!this.state.isProductVip) {
      delete values.premium_data;
    }
    if (!this.state.isProductStone) {
      delete values.stone_data;
    }

    return values
  }

  deleteProduct = async (confirm) => {
    const { product } = this.state;
    if (!confirm) {
      this.refDialogConfirmAction.current.open({
        message: `Вы действительно хотите удалить товар "${product.name}"?`,
        onSuccess: this.deleteProduct.bind(this, true)
      })
      return
    }

    this.setState({ isBackdrop: true })

    const res = await agent.delete(`/api/products/${ product.id }`).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err?.response || true}
    })

    this.setState({ isBackdrop: false });
    this.props.history.push('/products')
    Notification({
      type: NotificationTypes.success,
      message: "Товар успешно удален"
    })
  }

  changeValidation = () => {
    this.refFormik.current.handleSubmit();
  }

  changeValues = (values) => {
    if (!this.refFormik.current) {
      return
    }
    this.refFormik.current.setValues(values);
  }
  changeActiveTab = (activeTab) => {
    const currentUrl = new URL(window.location.href);
    let currentSearch = new URLSearchParams(currentUrl.search);
    currentSearch.set("content", activeTab);
    const search = Boolean(currentSearch) ? `?${currentSearch}` : ``;
    const reloadUrl = `${window?.location?.protocol || "https:"}//${window.location.host}${window.location.pathname}${search}`
    window.history.replaceState(null, null, reloadUrl);

    this.setState({
      activeTab
    })
  }

  changeIsStone = (val) => {
    const _values = this.refFormik.current.values;
    _values.is_stone = Number(val);
    this.refFormik.current.setValues(_values);
    this.setState({isProductStone: Boolean(val)});
  }
  changeIsPremium = (val) => {
    const _values = this.refFormik.current.values;
    _values.is_premium = Number(val);
    this.refFormik.current.setValues(_values);
    this.setState({isProductVip: Boolean(val)});
  }

  _getValidationSchema = () => {
    let object = {...validationSchemaCommon};
    if (this.state.isProductVip) {
      object = {...object, ...validationSchemaVip}
    }
    if (this.state.isProductStone) {
      object = {...object, ...validationSchemaStone}
    }

    return Yup.object().shape(object);
  }

  render() {
    const {
      product,
      activeTab,
      isBackdrop,
      isProductVip,
      isProductStone,
      isLoadProduct,
      isEditProduct,
      isErrorLoadProduct
    } = this.state;

    if (!!isLoadProduct) {
      return <LoadingContent/>
    }
    if (!!isErrorLoadProduct) {
      return <ErrorContent/>
    }
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between" mb={1} wrap="nowrap" sx={{width: "100%"}}>
          <Grid item>
            <Typography variant="h1">
              {isEditProduct ? 'Редактирование товара' : 'Создание товара'}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1} justifyContent="flex-end">
              {isEditProduct && (
                <Grid item>
                  <Button onClick={this.deleteProduct.bind(this, false)} variant="contained" color="error" size="small">
                    <DeleteIcon sx={{marginRight: "5px"}}/>
                    Удалить
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button onClick={this.changeValidation} variant="contained" color="primary" size="small">
                  <SaveIcon sx={{marginRight: "5px"}}/>
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Formik
            innerRef={this.refFormik}
            initialValues={product}
            validationSchema={this._getValidationSchema()}
            onSubmit={this.saveProduct}
          >{(props) => {
            const {
              values,
              errors,
              touched
            } = props;

            return (
              <>
                <Grid item xs={2}>
                  <NavigationMenuComponent
                    tab={activeTab}
                    errors={errors}
                    touched={touched}
                    product={product}
                    disabledVip={!isProductVip}
                    disabledStone={!isProductStone}
                    onChange={this.changeActiveTab}
                  />
                  <Box marginTop={1}>
                    <SectionSingleCheckBox
                      value={isProductVip}
                      title="VIP товар"
                      onChange={this.changeIsPremium}
                    />
                  </Box>
                  <Box marginTop={1}>
                    <SectionSingleCheckBox
                      value={isProductStone}
                      title="Камень товар"
                      onChange={this.changeIsStone}
                    />
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <VisibleContent visible={Boolean(activeTab === 'main-info')}>
                    <MainInfoSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'name-product')}>
                    <NameProductSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'product-description')}>
                    <ProductDescriptionSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'stone-metal')}>
                    <StoneMetalSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'seo-meta')}>
                    <SeoMetaSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'prices')}>
                    <PricesSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'guarantee')}>
                    <GuaranteeSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'additional-information')}>
                    <AdditionalInformationSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'inserts')}>
                    <InsertsSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'certificates')}>
                    <CertificatesSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'videos')}>
                    <VideosSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'headsets')}>
                    <HeadsetsSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'images')}>
                    <ImagesSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'files')}>
                    <FilesSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'vip-information')}>
                    <VipInformationSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'stone-section')}>
                    <StoneSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                  <VisibleContent visible={Boolean(activeTab === 'collection')}>
                    <CollectionsSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      onChange={this.changeValues}
                    />
                  </VisibleContent>
                </Grid>
              </>
            )
          }}</Formik>
        </Grid>


        <DialogConfirmAction ref={this.refDialogConfirmAction}/>
        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </>
    );
  }
}

const ErrorContent = React.memo(() => {
  return (
    <Box bgcolor="white" borderRadius="10px" padding="124px">
      <Typography variant="h1" color="error" align="center">E R R O R<br/>4 0 4</Typography>
      <Typography variant="h4" color="error" align="center" mt={2}>
        Запрашиваемый<br/>товар не найден
      </Typography>
    </Box>
  )
})
const LoadingContent = React.memo(() => {
  return (
    <Box bgcolor="white" borderRadius="10px" padding="124px">
      <Typography variant="h1" color="secondary" align="center">L O A D I N G</Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <CircularProgress color="secondary"/>
      </Box>
    </Box>
  )
})
const VisibleContent = (props) => {
  const {visible, children} = props;
  if (!visible) {
    return null
  }
  return (
    <Box display={!visible ? 'none' : 'block'}>
      {children}
    </Box>
  )
}

const validationSchemaCommon = {
  sku: Yup.string().required('Заполните поле'),
  category_id: Yup.string().required('Заполните поле'),
  // model: Yup.string().nullable().required('Заполните поле'),
  ru: Yup.object().shape({
    name: Yup.string().required('Заполните поле'),
    locale_code: Yup.string().required('Заполните поле'),
  }),
  // en: Yup.object().shape({
  //   name: Yup.string().required('Заполните поле'),
  // }),
  // kaz: Yup.object().shape({
  //   name: Yup.string().required('Заполните поле'),
  // }),
  metal_ids: Yup.array(Yup.string().required())
      .when('stone_data', {
        is: (stone_data) => {
          // Проверяем заполненность stone_data
          if (!stone_data) return false;
          const { stone_field_file_id, ru } = stone_data;
          return !Boolean(
              stone_field_file_id ||
              (ru && (
                  ru.stone_origin ||
                  ru.stone_size ||
                  ru.stone_cut_type ||
                  ru.stone_form ||
                  ru.stone_color ||
                  ru.stone_field
              ))
          );
        },
        then: (schema) => schema.min(1, 'Заполните поле').required('Заполните поле'),
        otherwise: (schema) => schema.notRequired(),
      }),
  collection_ids: Yup.array(Yup.string().required('Заполните поле')),
  inserts: Yup.array().of(Yup.object().shape({
    stone_id: Yup.string().required('Заполните поле'),
    size: Yup.string().required('Заполните поле'),
    quantity: Yup.string().required('Заполните поле'),
  }))
};
const validationSchemaVip = {
  premium_data: Yup.object().shape({
    premium_collection_first_file_id: Yup.mixed().nullable().required('Выберите файл'),
    premium_collection_second_file_id: Yup.mixed().nullable().required('Выберите файл'),
    premium_central_stone_field_mobile_file_id: Yup.mixed().nullable().required('Выберите файл'),
    premium_certificate_title_file_id: Yup.mixed().nullable().required('Выберите файл'),
    premium_stone_file_id: Yup.mixed().nullable().required('Выберите файл'),
    premium_background_first_file_id: Yup.mixed().nullable().required('Выберите файл'),
    premium_background_second_file_id: Yup.mixed().nullable().required('Выберите файл'),
    premium_background_third_file_id: Yup.mixed().nullable().required('Выберите файл'),
    premium_background_mobile_file_id: Yup.mixed().nullable().required('Выберите файл'),
    premium_insert_mobile_file_id: Yup.mixed().nullable().required('Выберите файл'),
    premium_insert_file_id: Yup.mixed().nullable().required('Выберите файл'),
    premium_central_stone_size: Yup.mixed().nullable().required('Обязательно к заполнению'),
    premium_central_stone_purity: Yup.mixed().nullable().required('Обязательно к заполнению'),
    premium_certificate_title: Yup.mixed().nullable().required('Обязательно к заполнению'),
    ru: Yup.object().shape({
      premium_central_stone_title: Yup.string().nullable().required('Обязательно к заполнению'),
      premium_central_stone_description: Yup.string().nullable().required('Обязательно к заполнению'),
      premium_central_stone_field_description: Yup.string().nullable().required('Обязательно к заполнению'),
      premium_central_stone_color: Yup.string().nullable().required('Обязательно к заполнению'),
      premium_history_description: Yup.string().nullable().required('Обязательно к заполнению'),
      premium_additional_description: Yup.string().nullable().required('Обязательно к заполнению'),
      premium_collection_description: Yup.string().nullable().required('Обязательно к заполнению')
    }),
  }),
};
const validationSchemaStone = {
  stone_data: Yup.object().shape({
    stone_field_file_id: Yup.mixed().nullable().required('Выберите файл'),
    ru: Yup.object().shape({
      stone_origin: Yup.string().nullable().required('Заполните "Страна" во вкладке "Вставки" у центральной вставки.'),
      stone_size: Yup.string().nullable().required('Обязательно к заполнению'),
      stone_cut_type: Yup.string().nullable().required('Обязательно к заполнению'),
      stone_form: Yup.string().nullable().required('Заполните "Огранка" во вкладке "Вставки" у центральной вставки.'),
      stone_color: Yup.string().nullable().required('Обязательно к заполнению'),
      stone_field: Yup.string().nullable().required('Обязательно к заполнению'),
    }),
    stone_version_file_ids: Yup.array(Yup.mixed().required()).min(1, 'Выберите файл').required('Выберите файл')
  }),
};

export default Product
