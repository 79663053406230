import React from "react";
import {
	Box,
	Typography
} from "@mui/material";
import DecoupledEditor from 'ckdtr-decoupled-document-custom';

const config = {
	language: "en",
	// toolbar: {
	// 	items: ["heading", "|", "fontfamily", "fontsize", "fontColor", "fontBackgroundColor", "|", "bold", "italic", "underline", "strikethrough", "|", "alignment", "|", "numberedList", "bulletedList", "|", "outdent", "indent", "|", "link", "blockquote", "insertTable", "|", "undo", "redo", "removeFormat", "sourceEditing"],
	// 	removeItems: [
	// 		'heading', 'fontfamily', 'blockquote', 'insertTable', 'mediaEmbed', 'uploadImage'
	// 	]
	// },
	fontSize: {
		options: [
			44,
			36,
			24,
			18,
			13
		],
		supportAllValues: true
	}
}

const CKEditor = (props) => {
	const {
		name,
		label,
		value,
		onChange
	} = props;
	const refToolbar = React.createRef();
	const refEditable = React.createRef();

	React.useEffect(() => {
		(async () => {
			await new Promise(r => setTimeout(r, 500));
			await initComponent();
		})();
	}, [])

	const initComponent = async () => {
		refToolbar.current.innerHTML = "";
		refEditable.current.innerHTML = "";

		DecoupledEditor.create((value || ""), config)
			.then( editor => {
				refToolbar.current.appendChild(editor.ui.view.toolbar.element);
				refEditable.current.appendChild(editor.ui.view.editable.element);

				editor.model.document.on('change:data', () => {
					handleOnChange(editor.getData());
				});
			} )
			.catch( error => {
				console.error( 'There was a problem initializing the editor.', error )
			});
	}

	const handleOnChange = (value) => {
		onChange({
			target: {
				name: name,
				value
			}
		});
	}

	return (
		<>
			<Box mb={1}>
				<Typography variant={"subtitle1"}>{label}</Typography>
			</Box>
			<Box className="container-ckeditor">
				<div ref={refToolbar}/>
				<div ref={refEditable}/>
			</Box>
		</>
	);
}

export default React.memo(CKEditor)
