import React from "react";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  IconButton,
  Typography,

  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";
import {
  makeStyles,
  withStyles
} from "@mui/styles";
import {
  Delete as DeleteIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {palette} from "../../../../../../theme/common";

class Files extends React.PureComponent {
  constructor(props) {
    super(props);

    this.refInput = React.createRef();
  }

  addFile = (type, event) => {
    let files = [];
    Array.from(event?.target?.files).forEach((file) => {
      files.push({
        file,
        fileType: type
      })
    });

    let values = {...this.props.values};
    values.file_ids = [...values.file_ids, ...files];

    this.props.onChange(values);
    this.refInput.current.value = null;
  }
  deleteFile = (index) => {
    let values = {...this.props.values};
    values.file_ids.splice(index, 1);
    this.props.onChange(values);
  }

  changeFull = (files) => {
    let values = {...this.props.values};
    values.file_ids = files;
    this.props.onChange(values);
  }

  render () {
    const {
      values,
      touched,
      classes
    } = this.props;

    return (
      <>

        <Box className={classes.whiteSection}>
          <Typography variant="h3" mb={1}>Фотографии</Typography>
          <ImagesSection
            values={(values?.file_ids || [])}
            onChange={this.addFile.bind(this, 'image')}
            onChangeFull={this.changeFull}
          />
        </Box>

        <Box mt={2}/>

        <Box className={classes.whiteSection}>
          <Typography variant="h3" mb={1}>Сертификаты</Typography>
          <Grid container spacing={2}>
            {(values?.file_ids || []).map((file, index) => {
              if (file.fileType !== "certificate") {
                return null
              }
              return (
                <Grid item>
                  <FileComponent
                    key={`file-${ file?.id || file }`}
                    file={file}
                    onDelete={this.deleteFile.bind(this, index)}
                  />
                </Grid>
              )
            })}
          </Grid>
          <Box mt={2}>
            <label style={{width: "100%"}}>
              <Button
                fullWidth
                component="span"
                variant="contained"
              >
                Добавить файл
              </Button>

              <input
                ref={this.refInput}
                style={{display: 'none'}}
                type="file"
                multiple
                onChange={this.addFile.bind(this, 'certificate')}
              />
            </label>
          </Box>
        </Box>

      </>
    )
  }
}
const ImagesSection = React.memo((props) => {
  const {
    values,
    onChange,
    onChangeFull
  } = props;
  const refInput = React.createRef();

  const handleAddFiles = (event) => {
    onChange(event);
    refInput.current.value = null;
  }
  const handleUpFile = (index) => {
    let newIndex = index - 1;
    if (newIndex < 0) {
      newIndex = 0;
    }

    let newValues = [...values];
    newValues[index] = newValues.splice(newIndex, 1, values[index])[0];

    onChangeFull(newValues);
  }
  const handleDownFile = (index) => {
    const totalCount = (values || []).filter((value) => Boolean(value.fileType === "image" || value.fileType === "attachment")).length;

    let newIndex = index + 1;
    if (newIndex > totalCount - 1) {
      newIndex = totalCount - 1;
    }

    let newValues = [...values];
    newValues[index] = newValues.splice(newIndex, 1, values[index])[0];

    onChangeFull(newValues);
  }
  const handleDeleteFile = (index) => {
    let newValues = [...values];
    newValues.splice(index, 1);
    onChangeFull(newValues);
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={100}>Изображение</TableCell>
            <TableCell>Наименование</TableCell>
            <TableCell align="right"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((value, index) => {
            if (Boolean(value.fileType !== "image" && value.fileType !== "attachment")) {
              return null
            }

            const imagePath = value?.url || URL.createObjectURL(value.file);
            const imageName = value?.name || value.file?.name;

            return (
              <TableRow>
                <TableCell align="center">
                  <Tooltip arrow="" title={<React.Fragment>
                    <img src={imagePath} width={240} height={240} style={{objectFit: "contain"}}/>
                  </React.Fragment>}>
                    <img src={imagePath} width={64} height={64} style={{objectFit: "contain"}}/>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '520px', display: 'block', textOverflow: 'ellipsis'}}>{imageName}</span>
                </TableCell>
                <TableCell align="right">
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <IconButton onClick={handleDownFile.bind(this, index)}>
                        <KeyboardArrowDownIcon/>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={handleUpFile.bind(this, index)}>
                        <KeyboardArrowUpIcon/>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={handleDeleteFile.bind(this, index)} color="error">
                        <DeleteIcon/>
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      <label style={{width: "100%"}}>
        <Button variant="contained" size="small" component="span" fullWidth>
          Добавить фото
        </Button>
        <input
          ref={refInput}
          type="file"
          accept="image/*"
          style={{display: 'none'}}
          multiple
          onChange={handleAddFiles}
        />
      </label>
    </>
  )
});

const FileComponent = React.memo((props) => {
  const {
    file,
    onDelete
  } = props;
  const classes = useStyles();

  const handleOpenFile = () => {
    const basePath = "";
    const filePath = Boolean(file?.id) ? file?.url : URL.createObjectURL(file);
    const path = [basePath, filePath].filter((t) => !!t).join("");
    window.open(path, '_blank')
  }

  return (
    <Box
      className={classes.elementFile}
    >
      { file?.file?.name || file?.name }

      <ToggleButtonGroup
        color="primary"
        exclusive
      >
        <ToggleButton onClick={handleOpenFile}>Открыть</ToggleButton>
        <ToggleButton onClick={onDelete}>Удалить</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
})

const styles = {
  whiteSection: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px 24px",
    boxSizing: "border-box",
  },
};
Files = withStyles(styles)(Files);

const useStyles = makeStyles(() => ({
  elementFile: {
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    borderRadius: "4px",
    border: "1px solid",
    borderColor: palette.primary.main,

    fontSize: 18,
    fontWeight: "600",

    "& .MuiToggleButtonGroup-root": {
      marginLeft: 16
    },
    "& .MuiButtonBase-root": {
      padding: "8px 12px",
      lineHeight: "100%",
      height: "auto"
    }
  }
}));

export {FileComponent, ImagesSection}
export default Files
