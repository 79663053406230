const getMetaData = (html, name) => {

	let metadata = {}
	html.replace(/<meta.+(property|name)="(.*?)".+content="(.*?)".*\/>/igm, (m,p0, p1, p2)=> {
		metadata[p1] = decode(p2)
	});

	return metadata?.[name]
}

const decode = (str) => str.replace(/&#(\d+);/g, function(match, dec) {
	return String.fromCharCode(dec);
})

export {
	getMetaData
}