import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  Backdrop,
  Typography,
  CircularProgress
} from "@mui/material";
import {
  ImportExport,
  Filters,
  Table as TableComponent
} from "./components";
import {
  DialogConfirmAction
} from "../../../../components";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";
import queryString from "query-string";
import {downloadFile} from "../../../../helper/files";

const initFilter = {
  "filters[category_id]": "",
  "filters[stone_id]": "",
  "filters[name]": "",
  "filters[locale_code]": "",
  "filters[sku]": "",
  "filters[is_stone]": "",
  "filters[model]": "",
  "filters[is_premium]": "",
  "filters[is_sold]": "",
  "filters[is_hidden]": "",
  "filters[is_novelty]": "",
  sort: "created_at",
  page: 1,
  per_page: 20
}

class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      categories: [],

      filter: {...initFilter},
      pagination: {
        totalPages: 1,
        totalProducts: 0,
      },

      isBackdrop: false,
      isLoadingProducts: true
    };
    this.refDialogConfirmAction = React.createRef();
  }

  componentDidMount = async () => {
    await this.parseFilterUrl();
    await this.getCategories();
    await this.getProducts();
  }

  getProducts = async () => {
    this.setState({
      products: [],
      isLoadingProducts: true
    })
    const filter = this._filterApi();
    const res = await agent.get(`/api/products?${filter}`, {
      params: {
        locale: "ru"
      }
    }).then((res) => {
      return res
    }).catch(() => {
      return
    })
    this.setState({
      products: (res.data?.data || []),
      pagination: {
        totalProducts: res?.data?.meta?.total || 0,
        totalPages: res?.data?.meta?.last_page || 0
      },
      isLoadingProducts: false
    });
    this.setFilterUrl();
  }
  getCategories = async () => {
    const res = await agent.get(`/api/categories?per_page=100&locale=ru`).then((res) => {
      return res.data?.data || []
    }).catch(() => {
      return []
    });
    this.setState({
      categories: res
    })
  }

  deleteProduct = async (product, confirm) => {
    if (!confirm) {
      this.refDialogConfirmAction.current.open({
        message: `Вы действительно хотите удалить товар "${product.name}"?`,
        onSuccess: this.deleteProduct.bind(this, product, true)
      })
      return
    }

    this.setState({ isBackdrop: true })

    const res = await agent.delete(`/api/products/${ product.id }`).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err?.response || true}
    })
    await this.getProducts();
    this.setState({ isBackdrop: false })
    Notification({
      type: NotificationTypes.success,
      message: "Товар успешно удален"
    })
  }
  updateProduct = async (product) => {
    this.setState({ isBackdrop: true })
    const res = await agent.put(`/api/products/${ product.id }`, product).then((res)=> {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    if (res?.error) {
      this.setState({ isBackdrop: false });

      let message = [];
      Object.keys(res?.error?.data?.data || {}).map((errorKey) => {
        const errorValues = res?.error?.data?.data?.[errorKey];
        const errorMessage = Array.isArray(errorValues) ? errorValues.join(", ") : errorValues;
        message.push(errorMessage)
      });

      Notification({
        type: NotificationTypes.error,
        message: message.join(";<br/>") || "Ошибка сервера"
      })

      return
    }
    await this.getProducts();
    this.setState({ isBackdrop: false });
    Notification({
      type: NotificationTypes.success,
      message: "Товар успешно изменен"
    });
  }

  // Логика с фильтрами
  setFilterUrl = () => {
    const filterString = this._filterApi();

    const pathname = window?.location?.pathname || "";
    const newPathname = [pathname, filterString].filter((t) => !!t).join("?");

    window.history.replaceState(null, null, newPathname);
  }
  parseFilterUrl = () => {
    const locationSearch = this.props?.location?.search || "";
    let filter = queryString.parse(locationSearch, {
      arrayFormat: "bracket"
    });
    if (filter.sort_by && filter.sort_direction) {
      filter.sort = [
        Boolean(filter.sort_direction === "asc") ? "-" : "",
        filter.sort_by
      ].filter((t) => !!t).join("");
      delete filter.sort_by;
      delete filter.sort_direction;
    }
    this.setState({
      filter: {
        ...initFilter,
        ...filter
      }
    });
  }
  changeFilter = async (filter, isFastStart) => {
    await this.setState({ filter });
    if (!isFastStart) {
      return
    }
    await this.getProducts();
  }
  resetFilter = async () => {
    await this.setState({filter: {...initFilter}});
    await this.getProducts();
  }
  _filterApi = () => {
    const filter = {...this.state.filter};

    let list = [];
    Object.keys(filter).map((filterKey) => {
      const value = filter?.[filterKey];
      if (!!value && filterKey === "sort") {
        const sort_by = value.replace("-", "");
        const sort_direction = Boolean(value[0] === "-") ? "asc" : "desc";
        list.push(`sort_by=${sort_by}`);
        list.push(`sort_direction=${sort_direction}`);
      }
      else if (!!value) {
        list.push(`${filterKey}=${value}`);
      }
    })

    return list.join("&")
  }

  exportProducts = async () => {
    await this.setState({ isBackdrop: true });
    await downloadFile([process.env.REACT_APP_HOST_API, '/api/exportProducts'].join(''));
    await this.setState({ isBackdrop: false });
  }
  exportSoldProducts = async () => {
    await this.setState({ isBackdrop: true });
    await downloadFile([process.env.REACT_APP_HOST_API, '/api/exportSoldProducts'].join(''));
    await this.setState({ isBackdrop: false });
  }

  render() {
    const {
      products,
      categories,
      filter,
      pagination,
      isBackdrop,
      isLoadingProducts
    } = this.state;

    return (
      <>

        <Grid container alignItems="center" justifyContent="space-between" mb={2}>
          <Grid item>
            <Typography variant="h1">Товары</Typography>
          </Grid>
          <Grid item>
            <ImportExport
              onExportProducts={this.exportProducts}
              onExportSoldProducts={this.exportSoldProducts}
            />
          </Grid>
        </Grid>

        <Filters
          filter={filter}
          onChange={this.changeFilter}
          onSearch={this.getProducts}
          onReset={this.resetFilter}
        />

        <Box mt={2}/>

        <TableComponent
          data={products}
          categories={categories}
          filter={filter}
          totalPage={pagination.totalPages}
          isLoad={isLoadingProducts}
          onChange={this.changeFilter}
          onUpdateProduct={this.updateProduct}
          onDeleteProduct={this.deleteProduct}
        />

        <DialogConfirmAction
          ref={this.refDialogConfirmAction}
        />


        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

      </>
    );
  }
}

export default Products
