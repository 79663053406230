import React from "react";
import {
  Box,
  Chip,
  Grid,
  Button,
  Divider,
  Typography,
  IconButton, FormHelperText
} from "@mui/material";
import {
   makeStyles
} from "@mui/styles";
import {
  Close as CloseIcon,
  CameraAlt as CameraAltIcon
} from "@mui/icons-material";
import {SectionDescription, SectionInput, SectionInputMask} from "../MainInfo";

const VipInformation = (props) => {
  const {
    values,
    errors,
    touched,
    onChange
  } = props;
  const classes = useStyles();
  const [activeLang, setActiveLang] = React.useState('RU');

  const handleDeleteFile = (name) => {
    let newValues = {...values};
    newValues['premium_data'][name] = null;
    onChange(newValues);
  }
  const handleChangeFile = ({target}) => {
    const { name, value } = target;
    let newValues = {...values};
    newValues['premium_data'][name] = value;
    onChange(newValues);
  }
  const handleChangeForm = (name, value) => {
    let newValues = {...values};
    newValues['premium_data'][name] = value;
    onChange(newValues);
  }
  const handleChangeFormLang = (lang, name, value) => {
    let newValues = {...values};
    newValues['premium_data'][lang][name] = value;
    onChange(newValues);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box className={classes.whiteSection}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container alignItems="flex-end">
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Chip label="RU" variant={Boolean(activeLang === 'RU') ? 'filled' : 'outlined'} onClick={() => setActiveLang('RU')}/>
                    </Grid>
                    <Grid item>
                      <Chip label="EN" variant={Boolean(activeLang === 'EN') ? 'filled' : 'outlined'} onClick={() => setActiveLang('EN')}/>
                    </Grid>
                    <Grid item>
                      <Chip label="KAZ" variant={Boolean(activeLang === 'KAZ') ? 'filled' : 'outlined'} onClick={() => setActiveLang('KAZ')}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{flex: 1}}>
                  <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
                </Grid>
              </Grid>
            </Grid>

            <VisibleContent visible={Boolean(activeLang === 'RU')}>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['ru']['premium_central_stone_title']}
                  title="Название центрального камня"
                  errors={errors?.premium_data?.ru?.premium_central_stone_title}
                  touched={touched?.premium_data?.ru?.premium_central_stone_title}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'premium_central_stone_title')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['ru']['premium_central_stone_description']}
                  title="Описание центрального камня"
                  errors={errors?.premium_data?.ru?.premium_central_stone_description}
                  touched={touched?.premium_data?.ru?.premium_central_stone_description}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'premium_central_stone_description')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['ru']['premium_central_stone_field_description']}
                  title="Описание месторождения"
                  errors={errors?.premium_data?.ru?.premium_central_stone_field_description}
                  touched={touched?.premium_data?.ru?.premium_central_stone_field_description}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'premium_central_stone_field_description')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['ru']['premium_central_stone_color']}
                  title="Цвет центрального камня"
                  errors={errors?.premium_data?.ru?.premium_central_stone_color}
                  touched={touched?.premium_data?.ru?.premium_central_stone_color}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'premium_central_stone_color')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['ru']['premium_history_description']}
                  title="Описание истории создания украшения"
                  errors={errors?.premium_data?.ru?.premium_history_description}
                  touched={touched?.premium_data?.ru?.premium_history_description}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'premium_history_description')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['ru']['premium_additional_description']}
                  title="Доп. описание украшения"
                  errors={errors?.premium_data?.ru?.premium_additional_description}
                  touched={touched?.premium_data?.ru?.premium_additional_description}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'premium_additional_description')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['ru']['premium_collection_description']}
                  title="История коллекции"
                  errors={errors?.premium_data?.ru?.premium_collection_description}
                  touched={touched?.premium_data?.ru?.premium_collection_description}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'premium_collection_description')}
                />
              </Grid>
            </VisibleContent>
            <VisibleContent visible={Boolean(activeLang === 'EN')}>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['en']['premium_central_stone_title']}
                  title="Название центрального камня"
                  errors={errors?.premium_data?.ru?.premium_central_stone_title}
                  touched={touched?.premium_data?.ru?.premium_central_stone_title}
                  onChange={handleChangeFormLang.bind(this, 'en', 'premium_central_stone_title')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['en']['premium_central_stone_description']}
                  title="Описание центрального камня"
                  errors={errors?.premium_data?.ru?.premium_central_stone_description}
                  touched={touched?.premium_data?.ru?.premium_central_stone_description}
                  onChange={handleChangeFormLang.bind(this, 'en', 'premium_central_stone_description')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['en']['premium_central_stone_field_description']}
                  title="Описание месторождения"
                  errors={errors?.premium_data?.ru?.premium_central_stone_field_description}
                  touched={touched?.premium_data?.ru?.premium_central_stone_field_description}
                  onChange={handleChangeFormLang.bind(this, 'en', 'premium_central_stone_field_description')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['en']['premium_central_stone_color']}
                  title="Цвет центрального камня"
                  errors={errors?.premium_data?.ru?.premium_central_stone_color}
                  touched={touched?.premium_data?.ru?.premium_central_stone_color}
                  onChange={handleChangeFormLang.bind(this, 'en', 'premium_central_stone_color')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['en']['premium_history_description']}
                  title="Описание истории создания украшения"
                  errors={errors?.premium_data?.ru?.premium_history_description}
                  touched={touched?.premium_data?.ru?.premium_history_description}
                  onChange={handleChangeFormLang.bind(this, 'en', 'premium_history_description')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['en']['premium_additional_description']}
                  title="Доп. описание украшения"
                  errors={errors?.premium_data?.ru?.premium_additional_description}
                  touched={touched?.premium_data?.ru?.premium_additional_description}
                  onChange={handleChangeFormLang.bind(this, 'en', 'premium_additional_description')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['en']['premium_collection_description']}
                  title="История коллекции"
                  errors={errors?.premium_data?.ru?.premium_collection_description}
                  touched={touched?.premium_data?.ru?.premium_collection_description}
                  onChange={handleChangeFormLang.bind(this, 'en', 'premium_collection_description')}
                />
              </Grid>
            </VisibleContent>
            <VisibleContent visible={Boolean(activeLang === 'KAZ')}>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['kaz']['premium_central_stone_title']}
                  title="Название центрального камня"
                  errors={errors?.premium_data?.ru?.premium_central_stone_title}
                  touched={touched?.premium_data?.ru?.premium_central_stone_title}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'premium_central_stone_title')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['kaz']['premium_central_stone_description']}
                  title="Описание центрального камня"
                  errors={errors?.premium_data?.ru?.premium_central_stone_description}
                  touched={touched?.premium_data?.ru?.premium_central_stone_description}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'premium_central_stone_description')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['kaz']['premium_central_stone_field_description']}
                  title="Описание месторождения"
                  errors={errors?.premium_data?.ru?.premium_central_stone_field_description}
                  touched={touched?.premium_data?.ru?.premium_central_stone_field_description}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'premium_central_stone_field_description')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['kaz']['premium_central_stone_color']}
                  title="Цвет центрального камня"
                  errors={errors?.premium_data?.ru?.premium_central_stone_color}
                  touched={touched?.premium_data?.ru?.premium_central_stone_color}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'premium_central_stone_color')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['kaz']['premium_history_description']}
                  title="Описание истории создания украшения"
                  errors={errors?.premium_data?.ru?.premium_history_description}
                  touched={touched?.premium_data?.ru?.premium_history_description}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'premium_history_description')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['kaz']['premium_additional_description']}
                  title="Доп. описание украшения"
                  errors={errors?.premium_data?.ru?.premium_additional_description}
                  touched={touched?.premium_data?.ru?.premium_additional_description}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'premium_additional_description')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['premium_data']['kaz']['premium_collection_description']}
                  title="История коллекции"
                  errors={errors?.premium_data?.ru?.premium_collection_description}
                  touched={touched?.premium_data?.ru?.premium_collection_description}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'premium_collection_description')}
                />
              </Grid>
            </VisibleContent>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.whiteSection}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SectionInput
                value={String(values?.premium_data?.premium_central_stone_size)}
                name="premium_central_stone_size"
                title="Размер центрального камня"
                accept="image/*"
                scale={2}
                errors={errors?.premium_data?.premium_central_stone_size}
                touched={touched?.premium_data?.premium_central_stone_size}
                onChange={handleChangeForm.bind(this, 'premium_central_stone_size')}
              />
            </Grid>
            <Grid item xs={12}>
              <SectionInput
                value={String(values?.premium_data?.premium_central_stone_purity)}
                name="premium_central_stone_purity"
                title="Чистота центрального камня"
                accept="image/*"
                scale={2}
                errors={errors?.premium_data?.premium_central_stone_purity}
                touched={touched?.premium_data?.premium_central_stone_purity}
                onChange={handleChangeForm.bind(this, 'premium_central_stone_purity')}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.whiteSection}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <FileUpload
                value={values?.premium_data?.premium_stone_file_id}
                title="Изображение для описания камня"
                accept="image/*"
                name="premium_stone_file_id"
                errors={errors?.premium_data?.premium_stone_file_id}
                touched={touched?.premium_data?.premium_stone_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.whiteSection}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SectionInput
                value={String(values?.premium_data?.premium_certificate_title)}
                name="premium_certificate_title"
                title="Наименование для международного сертификата"
                accept="image/*"
                scale={2}
                errors={errors?.premium_data?.premium_certificate_title}
                touched={touched?.premium_data?.premium_certificate_title}
                onChange={handleChangeForm.bind(this, 'premium_certificate_title')}
              />
            </Grid>
            <Grid item xs={4}>
              <FileUpload
                value={values?.premium_data?.premium_certificate_title_file_id}
                title="Файл сертификата"
                accept="image/*"
                name="premium_certificate_title_file_id"
                errors={errors?.premium_data?.premium_certificate_title_file_id}
                touched={touched?.premium_data?.premium_certificate_title_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.whiteSection}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <FileUpload
                value={values?.premium_data?.premium_collection_first_file_id}
                title="Иконка коллекции"
                accept="image/*"
                name="premium_collection_first_file_id"
                errors={errors?.premium_data?.premium_collection_first_file_id}
                touched={touched?.premium_data?.premium_collection_first_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
            <Grid item xs={4}>
              <FileUpload
                value={values?.premium_data?.premium_collection_second_file_id}
                title="Вторая иконка коллекции"
                accept="image/*"
                name="premium_collection_second_file_id"
                errors={errors?.premium_data?.premium_collection_second_file_id}
                touched={touched?.premium_data?.premium_collection_second_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
            <Grid item xs={4}>
              <FileUpload
                value={values?.premium_data?.premium_central_stone_field_mobile_file_id}
                title="Картинка месторождения мобильная"
                accept="image/*"
                name="premium_central_stone_field_mobile_file_id"
                errors={errors?.premium_data?.premium_central_stone_field_mobile_file_id}
                touched={touched?.premium_data?.premium_central_stone_field_mobile_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.whiteSection}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <FileUpload
                value={values?.premium_data?.premium_background_first_file_id}
                title="Фон характеристик ПК - 1"
                accept="image/*"
                name="premium_background_first_file_id"
                errors={errors?.premium_data?.premium_background_first_file_id}
                touched={touched?.premium_data?.premium_background_first_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
            <Grid item xs={4}>
              <FileUpload
                value={values?.premium_data?.premium_background_second_file_id}
                title="Фон характеристик ПК - 2"
                accept="image/*"
                name="premium_background_second_file_id"
                errors={errors?.premium_data?.premium_background_second_file_id}
                touched={touched?.premium_data?.premium_background_second_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
            <Grid item xs={4}>
              <FileUpload
                value={values?.premium_data?.premium_background_third_file_id}
                title="Фон характеристик ПК - 3"
                accept="image/*"
                name="premium_background_third_file_id"
                errors={errors?.premium_data?.premium_background_third_file_id}
                touched={touched?.premium_data?.premium_background_third_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
            <Grid item xs={4}>
              <FileUpload
                value={values?.premium_data?.premium_background_mobile_file_id}
                title="Фон характеристик мобильный"
                accept="image/*"
                name="premium_background_mobile_file_id"
                errors={errors?.premium_data?.premium_background_mobile_file_id}
                touched={touched?.premium_data?.premium_background_mobile_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.whiteSection}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FileUpload
                value={values?.premium_data?.premium_insert_file_id}
                title="Фон вставок ПК"
                accept="image/*"
                name="premium_insert_file_id"
                errors={errors?.premium_data?.premium_insert_file_id}
                touched={touched?.premium_data?.premium_insert_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
            <Grid item xs={6}>
              <FileUpload
                value={values?.premium_data?.premium_insert_mobile_file_id}
                title="Фон вставок мобильный"
                accept="image/*"
                name="premium_insert_mobile_file_id"
                errors={errors?.premium_data?.premium_insert_mobile_file_id}
                touched={touched?.premium_data?.premium_insert_mobile_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}
const VisibleContent = React.memo(({ children, visible }) => {
  return Boolean(visible) ? children : null
})
const FileUpload = React.memo((props) => {
  const {
    title,
    value,
    accept,
    name,
    errors,
    touched,

    onChange,
    onDelete
  } = props;
  const classes = useStyles();
  const isVisibleControls = Boolean(value?.name);

  const handleChange = ({ target }) => {
    const file = target.files?.[0] || null;
    if (!file) {
      return
    }

    onChange({
      target: {
        name: name,
        value: file
      }
    });
  };
  const handleOpen = () => {
    let fileUrl = "";
    fileUrl = URL.createObjectURL(value);
    window.open(fileUrl, "_blank")
  }
  const handleDelete = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onDelete(name);
  }

  const _getUrlFile = () => {
    if (!value) {
      return ""
    }

    const basePath = "";
    const filePath = Boolean(value?.id) ? value?.url : URL.createObjectURL(value);
    return [basePath, filePath].filter((t) => !!t).join("");
  }

  return (
    <Box>
      <Typography variant="h4">{title}</Typography>

      <label style={{width: "100%"}}>
        <Box className={classes.fileUploadBox}>
          {!value && (
            <CameraAltIcon color="primary" sx={{fontSize: 64}}/>
          )}
          {!!value && (
            <>
              <img src={_getUrlFile()}/>
              <IconButton className={classes.fileUploadDelete} onClick={handleDelete}>
                <CloseIcon/>
              </IconButton>
            </>
          )}
        </Box>

        <input type="file" accept={accept} style={{opacity: 0}} onChange={handleChange}/>
      </label>

      {Boolean(touched && errors) && (
        <FormHelperText error={true}>{touched && errors}</FormHelperText>
      )}
    </Box>
  )
});

const useStyles = makeStyles(() => ({
  whiteSection: {
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxSizing: "border-box"
  },

  fileUploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "15px",
    marginTop: "10px",
    cursor: "pointer",

    "& img": {
      position: "absolute",
      top: 0, left: 0,
      width: "100%",
      height: "100%",
      objectFit: "contain"
    },
    "&:after": {
      content: "''",
      float: "left",
      paddingTop: "50%"
    }
  },
  fileUploadDelete: {
    position: "absolute",
    top: "10px!important", right: "10px!important"
  }
}));

export { FileUpload }
export default React.memo(VipInformation)
