export default [
	{
		label: "Товар",
		value: "App\\Models\\Product\\Product",
		link: "/products/"
	},
	{
		label: "Метал",
		value: "App\\Models\\Metal\\Metal",
		link: "/metals/"
	},
	{
		label: "Камень",
		value: "App\\Models\\Stone\\Stone",
		link: "/stones/"
	},
	{
		label: "Категория",
		value: "App\\Models\\Categories\\Category",
		link: "/categories/"
	},
	{
		label: "Коллекция",
		value: "App\\Models\\Collection\\Collection",
		link: "/collections/"
	},
	{
		label: "Месторождения",
		value: "App\\Models\\Country\\Country",
		link: "/mestorozhdeniya/"
	},
	// {
	// 	label: "Вставки",
	// 	value: "App\\Models\\Country\\Country",
	// 	link: "/branches/"
	// },
]
