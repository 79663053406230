import React from "react";
import {
  Grid,
  Button
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Link
} from "react-router-dom";

const ImportExport = (props) => {
  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item>
        <Button variant="contained" size="small" sx={{borderRadius: "4px"}} component={Link} to="/stones/create">
          Создать камень
        </Button>
      </Grid>

      <Link >

      </Link>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
}));

export default React.memo(ImportExport)
