import React from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  AutocompleteStones,
  AutocompleteCategories
} from "../../../../../../components"

const Filters = (props) => {
  const {
    filter,
    onSearch,
    onReset
  } = props;
  const classes = useStyles();

  const handleChange = ({ target }) => {
    const { name, value } = target;
    let newFilter = {...filter};
    newFilter[name] = value;
    newFilter.page = 1;
    props.onChange(newFilter);
  }

  return (
    <Box className={classes.root}>
      <Grid container wrap="nowrap">
        <Grid item sx={{flex: 1}}>
          <Grid container spacing={2}>
            <Grid item xs={2.4}>
              <TextField
                value={filter['filters[name]']}
                name="filters[name]"
                label="Наименование"
                size="small"
                placeholder="..."
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2.4}>
              <TextField
                value={filter['filters[code]']}
                name="filters[code]"
                label="Артикул"
                size="small"
                placeholder="..."
                fullWidth
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Button variant="outlined" size="small" onClick={onReset}>
                Сбросить
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" size="small" onClick={onSearch}>
                Поиск
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    padding: "15px 20px",
    boxSizing: "border-box",
    borderRadius: "10px"
  },

}));

export default React.memo(Filters)
