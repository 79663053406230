import React from "react";
import {
  Box,
  Grid,
  Button,
  Tooltip
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Delete as DeleteIcon,
  Upload as UploadIcon
} from "@mui/icons-material";

class FileUpload extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
    this.refInput = React.createRef();
  }

  deleteFile = (index) => {
    let value = [...this.props.value];
    value.splice(index, 1);
    this.props.onChange(value);
  }
  uploadFiles = (event) => {
    const files = event?.target?.files;
    let value = [...this.props.value];
    Object.keys(files).forEach((key) => {
      const file = files[key];
      value.push(file);
    });
    this.props.onChange(value);
    this.refInput.current.value = "";
  }

  render () {
    const {
      value,
      accept
    } = this.props;

    return (
      <Box>
        <Grid container spacing={2}>
          {value.map((element, index) => (
            <Grid item>
              <FileElement
                key={`FileElement-${index}`}
                item={element}
                onDelete={this.deleteFile.bind(this, index)}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container alignItems="center" justifyContent="center" mt={4}>
          <Grid item>
            <label>
              <Button
                component="span"
                variant="contained"
                endIcon={<UploadIcon/>}
              >
                Выбрать файлы
              </Button>
              <input
                ref={this.refInput}
                type="file"
                accept={accept}
                style={{display: "none"}}
                onChange={this.uploadFiles}
              />
            </label>
          </Grid>
        </Grid>
      </Box>
    )
  }
}
const FileElement = React.memo((props) => {
  const {
    item,
    onDelete
  } = props;
  const classes = useStyles();
  const [file, setFile] = React.useState(null);

  React.useEffect(() => {
    ( async () => {
      await getFile();
    })();
  }, [item]);

  const getFile = async () => {
    if (typeof item !== "number") {
      setFile(item)
      return
    }

    setFile({

    })
  }

  return (
    <Tooltip title="Удалить файл" arrow>
      <Box className={classes.elementFile} onClick={onDelete}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            { file?.name || "Тут наименование файла" }
          </Grid>
          <Grid item>
            <DeleteIcon/>
          </Grid>
        </Grid>
      </Box>
    </Tooltip>
  )
});

const useStyles = makeStyles(() => ({
  elementFile: {
    border: "1px solid #D6B46E",
    borderRadius: 8,
    padding: '6px 12px',
    cursor: 'pointer',

    "&:hover": {
      backgroundColor: "#D6B46E"
    }
  }
}));

export default FileUpload
