import React from "react";
import {
  Chip,
  Grid,
  Divider
} from "@mui/material";
import {
  SectionInputMask
} from "../MainInfo";

class Prices extends React.PureComponent {

  onChangeForm = (locale, name, value) => {
    let values = {...this.props.values};
    values[locale][name] = value;
    this.props.onChange(values);
  }

  render () {
    const {
      values
    } = this.props;
    const isDisabledEditPrice = Boolean((values?.product_ids || []).length > 0);

    return (
      <Grid container spacing={1}>

        {/* RU */}
        <Grid item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="RU"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionInputMask
            title="Цена, ₽"
            disabled={isDisabledEditPrice}
            value={String(values['ru']['price'])}
            onChange={this.onChangeForm.bind(this, 'ru', 'price')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInputMask
            title="Старая цена, ₽"
            disabled={isDisabledEditPrice}
            value={String(values['ru']['old_price'])}
            onChange={this.onChangeForm.bind(this, 'ru', 'old_price')}
          />
        </Grid>

        {/* EN */}
        <Grid mt={3} item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="EN"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionInputMask
            title="Цена, $"
            disabled={isDisabledEditPrice}
            value={String(values['en']['price'])}
            onChange={this.onChangeForm.bind(this, 'en', 'price')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInputMask
            title="Старая цена, $"
            disabled={isDisabledEditPrice}
            value={String(values['en']['old_price'])}
            onChange={this.onChangeForm.bind(this, 'en', 'old_price')}
          />
        </Grid>

        {/* KAZ */}
        <Grid mt={3} item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="KAZ"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionInputMask
            title="Цена, ₸"
            disabled={isDisabledEditPrice}
            value={String(values['kaz']['price'])}
            onChange={this.onChangeForm.bind(this, 'kaz', 'price')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInputMask
            title="Старая цена, ₸"
            disabled={isDisabledEditPrice}
            value={String(values['kaz']['old_price'])}
            onChange={this.onChangeForm.bind(this, 'kaz', 'old_price')}
          />
        </Grid>

      </Grid>
    )
  }
}

export default Prices
