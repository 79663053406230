import React from "react";
import {
  Chip, Divider,
  Grid,
} from "@mui/material";
import {
  SectionDescription
} from "../MainInfo";


class Guarantee extends React.PureComponent {

  onChangeForm = (locale, name, value) => {
    let values = {...this.props.values}
    values[locale][name] = value;
    this.props.onChange(values);
  }

  render() {
    const {
      values
    } = this.props;

    return (
      <Grid container spacing={1}>

        {/* RU */}
        <Grid item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="RU"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionDescription
            align="top"
            title="Текст гарантии"
            value={values['ru']['guarantee_text']}
            multiline
            rows={3}
            onChange={this.onChangeForm.bind(this, 'ru', 'guarantee_text')}
          />
        </Grid>

        {/* EN */}
        <Grid mt={3} item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="EN"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionDescription
            align="top"
            title="Текст гарантии"
            value={values['en']['guarantee_text']}
            multiline
            rows={3}
            onChange={this.onChangeForm.bind(this, 'en', 'guarantee_text')}
          />
        </Grid>

        {/* KAZ */}
        <Grid mt={3} item xs={12}>
          <Grid container alignItems="flex-end">
            <Grid item>
              <Chip label="KAZ"/>
            </Grid>
            <Grid item sx={{flex: 1}}>
              <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SectionDescription
            align="top"
            title="Текст гарантии"
            value={values['kaz']['guarantee_text']}
            multiline
            rows={3}
            onChange={this.onChangeForm.bind(this, 'kaz', 'guarantee_text')}
          />
        </Grid>

      </Grid>
    )
  }
}

export default Guarantee
