import React from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem,

} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  AutocompleteStones,
  AutocompleteCategories
} from "../../../../../../components"

const Filters = (props) => {
  const {
    filter,
    onSearch,
    onReset
  } = props;
  const classes = useStyles();

  const handleChange = ({ target }) => {
    const { name, value } = target;
    let newFilter = {...filter};
    newFilter[name] = value;
    newFilter.page = 1;
    props.onChange(newFilter);
  }

  return (
    <Box className={classes.root}>
      <Grid container wrap="nowrap" spacing={4}>
        <Grid item sx={{flex: 1}}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <AutocompleteCategories
                value={filter['filters[category_id]']}
                name="filters[category_id]"
                label="Категория"
                size="small"
                placeholder="..."
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <AutocompleteStones
                value={filter['filters[stone_id]']}
                name="filters[stone_id]"
                label="Камень"
                size="small"
                placeholder="..."
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                value={filter['filters[name]']}
                name="filters[name]"
                label="Наименование"
                size="small"
                placeholder="..."
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                value={filter['filters[locale_code]']}
                name="filters[locale_code]"
                label="Код товара"
                size="small"
                placeholder="..."
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                value={filter['filters[model]']}
                name="filters[model]"
                label="Модель"
                size="small"
                placeholder="..."
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                value={filter['filters[sku]']}
                name="filters[sku]"
                label="Идентификационный номер"
                size="small"
                placeholder="..."
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={1.5}>
              <FormControl fullWidth size="small">
                <InputLabel>Наличие</InputLabel>
                <Select
                  value={filter['filters[is_sold]']}
                  label="Наличие"
                  name="filters[is_sold]"
                  onChange={handleChange}
                >
                  <MenuItem value="1">Продан</MenuItem>
                  <MenuItem value="0">В наличии</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1.5}>
              <FormControl fullWidth size="small">
                <InputLabel>Статус</InputLabel>
                <Select
                  value={filter['filters[is_hidden]']}
                  label="Наличие"
                  name="filters[is_hidden]"
                  onChange={handleChange}
                >
                  <MenuItem value="1">Отключен</MenuItem>
                  <MenuItem value="0">Включен</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControlLabel
                label="ВИП товар"
                checked={Boolean(filter['filters[is_premium]'])}
                control={<Checkbox />}
                onChange={(e, v) => handleChange({target: {name: 'filters[is_premium]', value: Number(!Boolean(filter['filters[is_premium]']))}})}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Камень товар"
                checked={Boolean(filter['filters[is_stone]'])}
                control={<Checkbox />}
                onChange={(e, v) => handleChange({target: {name: 'filters[is_stone]', value: Number(!Boolean(filter['filters[is_stone]']))}})}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                label="Новинка"
                checked={Boolean(filter['filters[is_novelty]'])}
                control={<Checkbox />}
                onChange={(e, v) => handleChange({target: {name: 'filters[is_novelty]', value: Number(!Boolean(filter['filters[is_novelty]']))}})}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Button variant="outlined" size="small" onClick={onReset}>
                Сбросить
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" size="small" onClick={onSearch}>
                Поиск
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    padding: "15px 20px",
    boxSizing: "border-box",
    borderRadius: "10px"
  },

}));

export default React.memo(Filters)
