import React from "react";
import {
  Grid,
  Button
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Link
} from "react-router-dom";

const ImportExport = (props) => {
  const {
    onExportProducts,
    onExportSoldProducts,
  } = props;

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item>
        <Button variant="outlined" size="small" sx={{borderRadius: "4px"}}>
          Импорт
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" size="small" sx={{borderRadius: "4px"}} onClick={onExportSoldProducts}>
          Экспорт проданных товаров
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" size="small" sx={{borderRadius: "4px"}} onClick={onExportProducts}>
          Экспорт всех товаров
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" size="small" sx={{borderRadius: "4px"}}>
          Экспорт вставок
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" size="small" sx={{borderRadius: "4px"}} component={Link} to="/products/create">
          Создать товар
        </Button>
      </Grid>

      <Link >

      </Link>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
}));

export default React.memo(ImportExport)
