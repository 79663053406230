import React from "react";
import {
  Box,
  Grid,
  Chip,
} from "@mui/material";
import {
  SectionInput,
  SectionInputMask,
  SectionSingleCheckBox
} from "../../../../products/Product/components/MainInfo";

class MainInformation extends React.PureComponent {
  onChangeForm = (name, value) => {
    let values = {...this.props.values};
    values[name] = value;
    this.props.onChange(values);
  }
  onChangeSettings = (locale, name, value) => {
    let values = {...this.props.values};
    values[locale][name] = value;
    this.props.onChange(values);
  }

  render () {
    const {
      values,
      errors,
      touched
    } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SectionSingleCheckBox
            value={values?.settings?.is_hidden}
            title="Коллекция отключена"
            onChange={this.onChangeSettings.bind(this, 'settings',  'is_hidden')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInput
            value={values?.code}
            title="Код товара"
            errors={errors?.code}
            touched={touched?.code}
            onChange={this.onChangeForm.bind(this, 'code')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionInputMask
            value={String(values?.order)}
            title="Порядковый номер"
            errors={errors?.order}
            touched={touched?.order}
            onChange={this.onChangeForm.bind(this, 'order')}
          />
        </Grid>
      </Grid>
    )
  }
}

export default MainInformation
