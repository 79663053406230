export default [

  {
    id: 2,
    label: "English",
    locale: "en"
  },

  {
    id: 1,
    label: "Русский",
    locale: "ru"
  }

]
