import React, {Component} from "react";
import {} from "@mui/material";
import agent from "../../../../agent/agent";
import {Notification, NotificationTypes} from "../../../../common/Notification";
import queryString from "query-string";

const initFilter = {
  page: 1,
  per_page: 20
}

class Headsets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],

      filter: {...initFilter},
      pagination: {
        totalPages: 1,
        totalProducts: 0,
      },

      isBackdrop: false,
      isLoadingProducts: true
    };
  }

  componentDidMount = async () => {
    await this.parseFilterUrl();
    await this.getProducts();
  }

  getProducts = async () => {
    this.setState({
      products: [],
      isLoadingProducts: true
    })
    const filter = this._filterApi();
    const res = await agent.get(`/api/collections?${filter}`, {
      params: {
        locale: "ru"
      }
    }).then((res) => {
      return res
    }).catch(() => {
      return
    })
    this.setState({
      products: (res.data?.data || []),
      pagination: {
        totalProducts: res?.data?.meta?.total || 0,
        totalPages: res?.data?.meta?.last_page || 0
      },
      isLoadingProducts: false
    });
    this.setFilterUrl();
  }
  deleteProduct = async (collectionId, isConfirm) => {
    if (!isConfirm) {
      this.refDialogConfirmAction.current.open({
        message: "Вы действительно хотите удалить коллекцию?",
        onSuccess: this.deleteProduct.bind(this, collectionId, true)
      })
      return
    }
    this.setState({ isBackdrop: true });
    const res = await agent.delete(`/api/collections/${ collectionId }`).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: err.response}
    });
    if (res.error) {
      this.setState({ isBackdrop: false });
      return
    }
    await this.getProducts();
    this.setState({ isBackdrop: false });
    Notification({
      type: NotificationTypes.success,
      message: "Коллекция успешно удалена"
    })
  }

  // Логика с фильтрами
  setFilterUrl = () => {
    const filterString = this._filterApi();

    const pathname = window?.location?.pathname || "";
    const newPathname = [pathname, filterString].filter((t) => !!t).join("?");

    window.history.replaceState(null, null, newPathname);
  }
  parseFilterUrl = () => {
    const locationSearch = this.props?.location?.search || "";
    let filter = queryString.parse(locationSearch, {
      arrayFormat: "bracket"
    });
    this.setState({
      filter: {
        ...initFilter,
        ...filter
      }
    });
  }
  changeFilter = async (filter, isFastStart) => {
    await this.setState({ filter });
    if (!isFastStart) {
      return
    }
    await this.getProducts();
  }
  resetFilter = async () => {
    await this.setState({filter: {...initFilter}});
    await this.getProducts();
  }
  _filterApi = () => {
    const filter = {...this.state.filter};

    let list = [];
    Object.keys(filter).map((filterKey) => {
      const value = filter?.[filterKey];
      if (!!value) {
        list.push(`${filterKey}=${value}`);
      }
    })

    return list.join("&")
  }

  render () {
    const {
      products,
      filter,
      pagination,
      isBackdrop,
      isLoadingProducts
    } = this.state;

    return (
      <>

      </>
    );
  }
}

export default Headsets
