import React from "react";
import {
  Autocomplete, FormHelperText,
  TextField
} from "@mui/material";
import agent from "../../../agent/agent";

class AutocompleteCollections extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      search: "",
      isLoad: false
    };
    this.timeOutSearch = null;
  }

  componentDidMount = async () => {

    if (Boolean(this.props.value)) {
      await this.inititSelectedValues();
    }

  }
  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    if (prevProps?.value !== this.props?.value) {
      await this.inititSelectedValues();
    }
  }

  inititSelectedValues = async () => {
    await this.setState({isLoad: true})
    const data = await agent.get(`/api/collections?locale=ru&filters[id]=${this.props.value}`).then((res) => {
      return res.data?.data || []
    }).catch(() => {
      return []
    })

    this.setState({
      list: data,
      isLoad: false
    });
  }
  getOptions = async () => {
    this.setState({isLoad: true});
    const filter = Boolean(this.state.search) ? `filters[name]=${ this.state.search }` : "";
    const list = await agent.get(`/api/collections?locale=ru&${ filter }`).then((res) => {
      return res.data?.data || []
    }).catch(() => {
      return []
    });
    this.setState({
      list,
      isLoad: false
    });
  }
  changeSearch = async (event, search) => {
    clearTimeout(this.timeOutSearch);
    if (!event || event?.type === 'blur' || event?.type === 'click') {
      return
    }

    this.setState({
      search,
      isLoad: Boolean(search)
    });
    if (!search) {
      return
    }

    this.timeOutSearch = setTimeout(async () => {
      await this.getOptions();
    }, 1000);
  }

  onChange = (value) => {
    if (this.props.multiple) {
      const parseValue = [...value].map((item) => {
        return item?.id || item
      })

      this.props.onChange({
        target: {
          name: this.props.name,
          value: parseValue || []
        }
      });
      if (this.props.onChangeActiveFilterCountry) {
        // this.props.onChangeActiveFilterCountry(value.name);
      }
    }
    if (!this.props.multiple) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: value?.id || null
        }
      });
      if (this.props.onChangeActiveFilterCountry) {
        this.props.onChangeActiveFilterCountry(value.name);
      }
    }
  }

  _getOptionLabel = (row) => {
    const options = this.state.list;

    if (typeof row !== 'object') {
      const item = options.find((t) => String(t.id) === String(row));
      if (!item) {
        return ""
      }

      return `${item?.name} (ID: ${item.id})`
    }

    return `${row?.name} (ID: ${row.id})`
  }
  _filterOptions = (list, params) => {
    const inputValue = params?.inputValue || "";

    return [...list].filter((t) => {

      if (!inputValue) {
        return true
      }

      const nameLowerCase = t.name.toLowerCase();
      const inputValueLowerCase = inputValue.toLowerCase();

      return Boolean(nameLowerCase.indexOf(inputValueLowerCase) > -1)

    })
  }

  render() {
    const {
      value,
      name,

      ...commonProps
    } = this.props;
    const {
      list,
      isLoad
    } = this.state;

    return (
      <>

        <Autocomplete
          {...this.props}

          value={value}
          disablePortal
          options={list}
          onChange={(event, value) => this.onChange(value, name)}
          name={name}
          variant="outlined"
          fullWidth
          disableClearable
          autoComplete={false}
          loading={isLoad}
          onOpen={this.getOptions}
          limitTags={1}

          getOptionLabel={this._getOptionLabel}
          filterOptions={this._filterOptions}
          onInputChange={this.changeSearch}

          renderInput={(params) => <TextField
            {...params}
            label="Коллекция"
            fullWidth
            error={Boolean(commonProps.error)}
          />}
        />

        {Boolean(commonProps.error || commonProps.helperText) && (
          <FormHelperText error={commonProps.error}>
            {commonProps.helperText}
          </FormHelperText>
        )}

      </>
    );
  }

}

export default AutocompleteCollections
