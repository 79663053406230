const getObjectKeys = (object, str) => {
  let keys = [];
  Object.keys(object).map((key) => {
    const value = object[key];
    if (typeof value === "object") {
      keys.push(...getObjectKeys(value, [str, key].filter((t) => !!t).join(".")))
    } else {
      keys.push([str, key].filter((t) => !!t).join("."))
    }
  });
  return keys
};

export {
  getObjectKeys
}
