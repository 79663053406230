import React from "react";
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  FileUpload
} from "../../../../../../components";

class Certificates extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {}
  }

  changeForm = (value) => {
    let values = {...this.props.values};
    values.file_ids = value;
    this.props.onChange(values);
  }

  render () {
    const {
      values,
      classes
    } = this.props;

    return (
      <Box className={classes.whiteSection}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h3">Сертификаты</Typography>
          </Grid>
          <Grid item xs={12}>
            <FileUpload
              value={values.file_ids}
              name="file_ids"
              onChange={this.changeForm}
            />
          </Grid>
        </Grid>
      </Box>
    )
  }
}

const styles = {
  whiteSection: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px 24px",
    boxSizing: "border-box",
  },
};
Certificates = withStyles(styles)(Certificates);

export default Certificates
