// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Stones from './Stones';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(Stones);
