import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  Backdrop,
  TextField,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Notification,
  NotificationTypes
} from "../../../../common/Notification";
import agent from "../../../../agent/agent";

const labels = {
  "topPrice": "Предел верхней цены"
};
class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: [],
      isBackdrop: false
    };
  }

  componentDidMount = async () => {
    await this.getSettings();
  }

  getSettings = async () => {
    const res = await agent.get(`/api/settings?limit=999`).then((res) => {
      return res.data?.data || []
    }).catch(() => {
      return {}
    });
    this.setState({
      settings: res
    });
  }
  changeSettings = (index, { target }) => {
    const { value } = target;
    let settings = [...this.state.settings];
    settings[index]['value'] = value;
    this.setState({ settings });
  }
  submitSettings = async () => {
    this.setState({ isBackdrop: true });
    const { settings } = this.state;
    await Promise.all(settings.map(async (setting) => {
      const res = await agent.put(`/api/settings/${ setting.id }`, {
        type: setting.type,
        value: setting.value,
        locale: setting.locale,
      }).then((res) => {
        return res.data
      }).catch(() => {
        return null
      })
      console.log('res: ', res);
    }));
    await this.getSettings();
    this.setState({ isBackdrop: false });
    Notification({
      type: NotificationTypes.success,
      message: "Настройки успешно обновлены"
    })
  }

  render () {
    const {
      classes
    } = this.props;
    const {
      settings,
      isBackdrop
    } = this.state;

    return (
      <Container maxWidth="md">
        <Typography variant="h1" mb={1}>Настройки системы</Typography>
        <Box className={classes.whiteSection}>
          <Grid container spacing={3}>
            {settings.map((setting, index) => (
              <Grid item xs={12}>
                <TextField
                  value={setting.value}
                  fullWidth
                  name="topPrice"
                  size="small"
                  label={`${labels[setting.type] || setting.type} (${setting.locale})`}
                  onChange={this.changeSettings.bind(this, index)}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button variant="contained" fullWidth={true} onClick={this.submitSettings}>
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </Box>


        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Container>
    );
  }
}

const styles = {
  whiteSection: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "20px 24px",
    boxSizing: "border-box",
  }
};
Settings = withStyles(styles)(Settings);

export default Settings
