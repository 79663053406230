import agent from "../agent/agent";

const CHANGE_LOCALE = 'languages/CHANGE_LOCALE';

const initialState = {
  locale: ""
};

export function changeLocale(locale) {
  localStorage.setItem('locale', locale)
  return {
    type: CHANGE_LOCALE,
    locale
  }
}

export default function LanguagesState(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_LOCALE: {
      let locale = action.locale
      return {
        ...state,
        locale
      }
    }
    default:
      return state;
  }
}
