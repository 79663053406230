import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",

      "& tr > th": {
        backgroundColor: palette.primary.main,

        fontSize: 15,
        lineHeight: "22px",
        color: "white",
        fontWeight: "600"
      },
      "& tr > th:first-child": {
        borderRadius: "10px 0 0 0"
      },
      "& tr > th:last-child": {
        borderRadius: "0 10px 0 0"
      },
      "&.small": {
        "& .MuiTableCell-root": {
          padding: "5px 10px"
        },
        "& tr > th": {
          fontSize: 14,
          lineHeight: "16px"
        },
        "& tr > th:first-child": {
          borderRadius: "5px 0 0 0"
        },
        "& tr > th:last-child": {
          borderRadius: "0 5px 0 0"
        },
      }
    }
  }
}
