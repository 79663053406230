import React from "react";
import {
  Box,
  Chip,
  Grid,
  Button,
  Divider,
  Typography,
  FormHelperText
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  SectionInput,
  SectionDescription
} from "../MainInfo";
import {FileUpload} from "../VipInformation";

const StoneSection = (props) => {
  const {
    values,
    errors,
    touched,
    onChange
  } = props;
  const classes = useStyles();
  const [activeLang, setActiveLang] = React.useState('RU');

  const handleChangeForm = (name, value) => {
    let newValues = {...values};
    newValues['stone_data'][name] = value;
    onChange(newValues);
  }
  const handleChangeFormLang = (lang, name, value) => {
    let newValues = {...values};
    newValues['stone_data'][lang][name] = value;
    onChange(newValues);
  }
  const handleDeleteFile = (name) => {
    let newValues = {...values};
    newValues['stone_data'][name] = null;
    onChange(newValues);
  }
  const handleChangeFile = ({target}) => {
    const { name, value } = target;
    let newValues = {...values};
    newValues['stone_data'][name] = value;
    onChange(newValues);
  }

  const handleSetRims = ({target}) => {
    const { files } = target;
    let newValues = {...values};
    newValues.stone_data.stone_rim_background_file_ids = [
      ...newValues.stone_data.stone_rim_background_file_ids,
      ...files
    ];
    onChange(newValues);
  }
  const handleDeleteRim = (index) => {
    let newValues = {...values};
    newValues.stone_data.stone_rim_background_file_ids.splice(index, 1);
    onChange(newValues);
  }

  const handleSetVersion = ({target}) => {
    const { files } = target;
    let newValues = {...values};
    newValues.stone_data.stone_version_file_ids = [
      ...newValues.stone_data.stone_version_file_ids,
      ...files
    ];
    onChange(newValues);
  }
  const handleDeleteVersion = (index) => {
    let newValues = {...values};
    newValues.stone_data.stone_version_file_ids.splice(index, 1);
    onChange(newValues);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box className={classes.whiteSection}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container alignItems="flex-end">
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Chip label="RU" variant={Boolean(activeLang === 'RU') ? 'filled' : 'outlined'} onClick={() => setActiveLang('RU')}/>
                    </Grid>
                    <Grid item>
                      <Chip label="EN" variant={Boolean(activeLang === 'EN') ? 'filled' : 'outlined'} onClick={() => setActiveLang('EN')}/>
                    </Grid>
                    <Grid item>
                      <Chip label="KAZ" variant={Boolean(activeLang === 'KAZ') ? 'filled' : 'outlined'} onClick={() => setActiveLang('KAZ')}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{flex: 1}}>
                  <Divider sx={{borderBottomWidth: "2px", borderColor: "rgba(0,0,0,0.08)"}}/>
                </Grid>
              </Grid>
            </Grid>

            <VisibleContent visible={Boolean(activeLang === 'RU')}>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['ru']['stone_origin']}
                  title="Происхождение"
                  disabled={true}
                  errors={errors?.stone_data?.ru?.stone_origin}
                  touched={touched?.stone_data?.ru?.stone_origin}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'stone_origin')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['ru']['stone_size']}
                  title="Размер, мм"
                  errors={errors?.stone_data?.ru?.stone_size}
                  touched={touched?.stone_data?.ru?.stone_size}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'stone_size')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['ru']['stone_form']}
                  title="Форма"
                  disabled={true}
                  errors={errors?.stone_data?.ru?.stone_form}
                  touched={touched?.stone_data?.ru?.stone_form}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'stone_form')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['ru']['stone_gentrification']}
                  title="Облагораживание"
                  errors={errors?.stone_data?.ru?.stone_gentrification}
                  touched={touched?.stone_data?.ru?.stone_gentrification}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'stone_gentrification')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['ru']['stone_transparency']}
                  title="Прозрачность"
                  errors={errors?.stone_data?.ru?.stone_transparency}
                  touched={touched?.stone_data?.ru?.stone_transparency}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'stone_transparency')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['ru']['stone_cut_type']}
                  title="Тип огранки"
                  errors={errors?.stone_data?.ru?.stone_cut_type}
                  touched={touched?.stone_data?.ru?.stone_cut_type}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'stone_cut_type')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['ru']['stone_color']}
                  title="Цвет"
                  errors={errors?.stone_data?.ru?.stone_color}
                  touched={touched?.stone_data?.ru?.stone_color}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'stone_color')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['stone_data']['ru']['stone_field']}
                  title="Месторождение"
                  errors={errors?.stone_data?.ru?.stone_field}
                  touched={touched?.stone_data?.ru?.stone_field}
                  onChange={handleChangeFormLang.bind(this, 'ru', 'stone_field')}
                />
              </Grid>
            </VisibleContent>
            <VisibleContent visible={Boolean(activeLang === 'EN')}>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['en']['stone_origin']}
                  title="Происхождение"
                  disabled={true}
                  errors={errors?.stone_data?.en?.stone_origin}
                  touched={touched?.stone_data?.en?.stone_origin}
                  onChange={handleChangeFormLang.bind(this, 'en', 'stone_origin')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['en']['stone_size']}
                  title="Размер, мм"
                  errors={errors?.stone_data?.en?.stone_size}
                  touched={touched?.stone_data?.en?.stone_size}
                  onChange={handleChangeFormLang.bind(this, 'en', 'stone_size')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['en']['stone_form']}
                  title="Форма"
                  disabled={true}
                  errors={errors?.stone_data?.en?.stone_form}
                  touched={touched?.stone_data?.en?.stone_form}
                  onChange={handleChangeFormLang.bind(this, 'en', 'stone_form')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['en']['stone_gentrification']}
                  title="Облагораживание"
                  errors={errors?.stone_data?.en?.stone_gentrification}
                  touched={touched?.stone_data?.en?.stone_gentrification}
                  onChange={handleChangeFormLang.bind(this, 'en', 'stone_gentrification')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['en']['stone_transparency']}
                  title="Прозрачность"
                  errors={errors?.stone_data?.en?.stone_transparency}
                  touched={touched?.stone_data?.en?.stone_transparency}
                  onChange={handleChangeFormLang.bind(this, 'en', 'stone_transparency')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['en']['stone_cut_type']}
                  title="Тип огранки"
                  errors={errors?.stone_data?.en?.stone_cut_type}
                  touched={touched?.stone_data?.en?.stone_cut_type}
                  onChange={handleChangeFormLang.bind(this, 'en', 'stone_cut_type')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['en']['stone_color']}
                  title="Цвет"
                  errors={errors?.stone_data?.en?.stone_color}
                  touched={touched?.stone_data?.en?.stone_color}
                  onChange={handleChangeFormLang.bind(this, 'en', 'stone_color')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['stone_data']['en']['stone_field']}
                  title="Месторождение"
                  errors={errors?.stone_data?.en?.stone_field}
                  touched={touched?.stone_data?.en?.stone_field}
                  onChange={handleChangeFormLang.bind(this, 'en', 'stone_field')}
                />
              </Grid>
            </VisibleContent>
            <VisibleContent visible={Boolean(activeLang === 'KAZ')}>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['kaz']['stone_origin']}
                  title="Происхождение"
                  disabled={true}
                  errors={errors?.stone_data?.kaz?.stone_origin}
                  touched={touched?.stone_data?.kaz?.stone_origin}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'stone_origin')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['kaz']['stone_size']}
                  title="Размер, мм"
                  errors={errors?.stone_data?.kaz?.stone_size}
                  touched={touched?.stone_data?.kaz?.stone_size}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'stone_size')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['kaz']['stone_form']}
                  title="Форма"
                  disabled={true}
                  errors={errors?.stone_data?.kaz?.stone_form}
                  touched={touched?.stone_data?.kaz?.stone_form}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'stone_form')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['kaz']['stone_gentrification']}
                  title="Облагораживание"
                  errors={errors?.stone_data?.kaz?.stone_gentrification}
                  touched={touched?.stone_data?.kaz?.stone_gentrification}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'stone_gentrification')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['kaz']['stone_transparency']}
                  title="Прозрачность"
                  errors={errors?.stone_data?.kaz?.stone_transparency}
                  touched={touched?.stone_data?.kaz?.stone_transparency}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'stone_transparency')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['kaz']['stone_cut_type']}
                  title="Тип огранки"
                  errors={errors?.stone_data?.kaz?.stone_cut_type}
                  touched={touched?.stone_data?.kaz?.stone_cut_type}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'stone_cut_type')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionInput
                  value={values['stone_data']['kaz']['stone_color']}
                  title="Цвет"
                  errors={errors?.stone_data?.kaz?.stone_color}
                  touched={touched?.stone_data?.kaz?.stone_color}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'stone_color')}
                />
              </Grid>
              <Grid item xs={12}>
                <SectionDescription
                  value={values['stone_data']['kaz']['stone_field']}
                  title="Месторождение"
                  errors={errors?.stone_data?.kaz?.stone_field}
                  touched={touched?.stone_data?.kaz?.stone_field}
                  onChange={handleChangeFormLang.bind(this, 'kaz', 'stone_field')}
                />
              </Grid>
            </VisibleContent>

            <Grid item xs={12}>
              <SectionInput
                value={values['stone_data']['stone_certificate']}
                title="Сертификат камня (наименование для международного сертификата)"
                errors={errors?.stone_data?.stone_certificate}
                touched={touched?.stone_data?.stone_certificate}
                onChange={handleChangeForm.bind(this, 'stone_certificate')}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.whiteSection}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <FileUpload
                value={values?.stone_data?.stone_background_of_the_gemological_description_file_id}
                title="Геммологическиое описание"
                accept="image/*"
                name="stone_background_of_the_gemological_description_file_id"
                errors={errors?.stone_data?.stone_background_of_the_gemological_description_file_id}
                touched={touched?.stone_data?.stone_background_of_the_gemological_description_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
            <Grid item xs={3}>
              <FileUpload
                value={values?.stone_data?.stone_field_file_id}
                title="Фото месторождения"
                accept="image/*"
                name="stone_field_file_id"
                errors={errors?.stone_data?.stone_field_file_id}
                touched={touched?.stone_data?.stone_field_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
            <Grid item xs={3}>
              <FileUpload
                value={values?.stone_data?.stone_certificate_file_id}
                title="Сертификат"
                accept="image/*"
                name="stone_certificate_file_id"
                errors={errors?.stone_data?.stone_certificate_file_id}
                touched={touched?.stone_data?.stone_certificate_file_id}

                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.whiteSection}>
          <Typography variant="h3">Фото оправ</Typography>
          <Grid container spacing={1}>
            {(values?.stone_data?.stone_rim_background_file_ids || []).map((file, index) => (
              <Grid item xs={3}>
                <FileUpload
                  value={file}
                  accept="image/*"
                  onDelete={handleDeleteRim.bind(this, index)}
                />
              </Grid>
            ))}
          </Grid>
          <Box mt={2}>
            <label>
              <Button variant="contained" component="span" fullWidth>
                Добавить файлы
              </Button>
              <input type="file" multiple accept="image/*" onChange={handleSetRims} style={{display: "none"}}/>
            </label>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.whiteSection}>
          <Typography variant="h3">Варианты исполнения</Typography>
          <Grid container spacing={1}>
            {(values?.stone_data?.stone_version_file_ids || []).map((file, index) => (
              <Grid item xs={3}>
                <FileUpload
                  value={file}
                  accept="image/*"
                  onDelete={handleDeleteVersion.bind(this, index)}
                />
              </Grid>
            ))}
          </Grid>
          <Box mt={2}>
            <label>
              <Button variant="contained" component="span" fullWidth>
                Добавить файлы
              </Button>
              <input type="file" multiple accept="image/*" onChange={handleSetVersion} style={{display: "none"}}/>
            </label>
          </Box>

          {Boolean(touched?.stone_data?.stone_version_file_ids && errors?.stone_data?.stone_version_file_ids) && (
            <FormHelperText error>
              {touched?.stone_data?.stone_version_file_ids && errors?.stone_data?.stone_version_file_ids}
            </FormHelperText>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
const VisibleContent = React.memo(({ children, visible }) => {
  return Boolean(visible) ? children : null
})
const useStyles = makeStyles(() => ({
  whiteSection: {
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxSizing: "border-box"
  }
}));

export default React.memo(StoneSection)
