// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Settings from './Settings';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(Settings);
