import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      backgroundColor: "white"
    },
    hiddenLabel: {
      "& fieldset": {
        top: 0
      },
      "& legend": {
        display: "none"
      },
    }
  }
}
