import React from "react";
import {
  Autocomplete, FormHelperText,
  TextField
} from "@mui/material";
import agent from "../../../agent/agent";

class AutocompleteMetals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],

      isLoad: false
    };
  }

  componentDidMount = async () => {
    if (Boolean(this.props.value)) {
      await this.getOptions();

      if (this.props.onChangeActiveFilterCountry) {
        this.props.onChangeActiveFilterCountry((this.state.list || []).find((t) => t.id === this.props.value)?.name);
      }
    }
  }
  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    if (prevProps?.value !== this.props?.value) {
      await this.getOptions();
    }
  }

  getOptions = async () => {
    if ((this.state?.list || []).length > 0) {
      return null
    }

    this.setState({isLoad: true});
    const list = await agent.get(`/api/metals?locale=ru&per_page=999`).then((res) => {
      return res.data?.data || []
    }).catch(() => {
      return []
    });
    this.setState({
      list,
      isLoad: false
    });
  }

  onChange = (value) => {
    if (this.props.multiple) {
      const parseValue = [...value].map((item) => {
        return item?.id || item
      })

      this.props.onChange({
        target: {
          name: this.props.name,
          value: parseValue || []
        }
      });
      if (this.props.onChangeActiveFilterCountry) {
        // this.props.onChangeActiveFilterCountry(value.name);
      }
    }
    if (!this.props.multiple) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: value?.id || null
        }
      });
      if (this.props.onChangeActiveFilterCountry) {
        this.props.onChangeActiveFilterCountry(value.name);
      }
    }
  }

  _getOptionLabel = (row) => {
    const options = this.state.list;

    if (typeof row !== 'object') {
      const item = options.find((t) => String(t.id) === String(row));

      if (!item) {
        return ""
      }

      return item?.name
    }

    return row.name
  }
  _filterOptions = (list, params) => {
    const values = Array.isArray(this.props.value) ? this.props.value : [this.props.value];
    const inputValue = params?.inputValue || "";

    return [...list]
      .filter((t) => !values.includes(t.id))
      .filter((t) => {
      if (!inputValue) {
        return true
      }

      const nameLowerCase = t.name.toLowerCase();
      const inputValueLowerCase = inputValue.toLowerCase();

      return Boolean(nameLowerCase.indexOf(inputValueLowerCase) > -1)

    })
  }

  render() {
    const {
      value,
      name,
      limitTags,

      ...commonProps
    } = this.props;
    const {
      list,
      isLoad
    } = this.state;

    return (
      <>

        <Autocomplete
          {...this.props}

          value={value}
          disablePortal
          options={list}
          onChange={(event, value) => this.onChange(value, name)}
          name={name}
          variant="outlined"
          fullWidth
          disableClearable
          autoComplete={false}
          loading={isLoad}
          onOpen={this.getOptions}
          limitTags={limitTags || 1}
          filterSelectedOptions={true}

          getOptionLabel={this._getOptionLabel}
          filterOptions={this._filterOptions}

          renderInput={(params) => <TextField
            {...params}
            label="Метал"
            fullWidth
            error={Boolean(commonProps.error)}
          />}
        />

        {Boolean(commonProps.error || commonProps.helperText) && (
          <FormHelperText error={commonProps.error}>
            {commonProps.helperText}
          </FormHelperText>
        )}

      </>
    );
  }

}

export default AutocompleteMetals
