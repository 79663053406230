import React from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {setUser, setCurrentBuildVersion} from '../states/global';
import {changeLocale} from "../states/languages";

import MainRoute from "./Main";
import NotAuth from "./NotAuth";
import agent from "../agent/agent";
import urls from "../variables/urls";
import axios from "axios";
import {getMetaData} from "../helper/meta";
import languages from "../constants/languages";
import {setCookie} from "../helper/cookie";

class Router extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  componentDidMount = async () => {
    await this.setLocale();
    await this.getUser();
    await this.setCurrentVersion();
  }

  getUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      this.setState({isLoading: false});
      setCookie('admin-auth', '');

      return null
    }

    const formSubmit = localStorage.getItem('form-auth');
    if (!formSubmit) {
      this.setState({isLoading: false});
      setCookie('admin-auth', '');
      return
    }

    const response = await agent.post('api/auth/login', JSON.parse(formSubmit)).then((res) => {
      return res
    }).catch((err) => {
      return null
    });
    const user = response?.data?.user || {};
    localStorage.setItem('token', response?.data?.token);
    agent.defaults.headers['Authorization'] = `Bearer ${response?.data?.token}`;
    this.props.setUser(user);
    setCookie('admin-auth', '1');
    this.setState({isLoading: false});
  }
  setLocale = async () => {
    const avaibleList = languages.map((t) => t.locale);
    let locale = (localStorage.getItem("locale") || navigator.language || navigator.userLanguage || '').split("-")?.[0];
    if (!avaibleList.includes(locale)) {
      locale = avaibleList[0]
    }
    this.props.setLocale(locale);
  }
  setCurrentVersion = async () => {
    const currentBuildVersion = await axios.get(`/index.html`, {}).then((res) => {
      return getMetaData(res.data, "build-version")
    }).catch((err) => {
      return ""
    });
    this.props.setCurrentBuildVersion(currentBuildVersion);
  }

  render() {
    const {isLoading} = this.state;
    const {user} = this.props.global;

    if (isLoading) {
      return null
    }
    if (!user) {
      return (
        <NotAuth/>
      )
    }

    return (
      <>
        <MainRoute/>
      </>
    )
  }
}

export default compose(
  connect(
    state => ({
      global: state.global
    }),
    dispatch => ({
      setUser: (user) => dispatch(setUser(user)),
      setLocale: (locale) => dispatch(changeLocale(locale)),

      setCurrentBuildVersion: (version) => dispatch(setCurrentBuildVersion(version)),
    }),
  ),
)(Router);
